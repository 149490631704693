import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import {
	LOAN_PLATFORMS,
	// INITIAL_GRAPH_OPTIONS,
	GRAPH_TYPES,
	INITIAL_GLOBAL_LOAN_HISTORY_GRAPH_OPTIONS,
} from "../../values/constants";
import { LIGHT_SELECT_STYLES, DARK_SELECT_STYLES } from "../../values/theme";
import CollectionItem from "./CollectionItem";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import {
	fetchMultipleCollections,
	searchCollectionsOnAlgolia,
} from "../../services/features/collection-service";
import ReactGA from "react-ga4";
import GlobalLoansIndex from "./GlobalLoansIndex";
import { BsFillInfoCircleFill } from "react-icons/bs";
import Switch from "react-switch";
import ScrollUpButton from "./ScrollUpButton";
import { fetchGlobalLoansHistory } from "../../services/features/loans-history-service";
import { adjustBarTheme } from "../../services/features/chart-theme-service";
import useWindowDimensions from "../../services/utils/Dimensions";

export const OverviewLoansPage = ({ theme, setTheme }) => {
	const windowDimensions = useWindowDimensions();

	const [collectionList, setCollectionList] = useState([]);
	const testMode = false;
	const [limit, setLimit] = useState(testMode ? 1 : 9);
	const [limitCap, setLimitCap] = useState(false);
	const [loanPlatform, setLoanPlatform] = useState({
		value: "all",
		label: "All Loans 🌐",
	});
	const [graphType, setGraphType] = useState({
		value: "scatter",
		label: "Loans Chart  📊",
	});
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const search = searchParams.get("search");
	const [globalGraphData, setGlobalGraphData] = useState(null);
	const [globalChartOptions, setGlobalChartOptions] = useState(
		INITIAL_GLOBAL_LOAN_HISTORY_GRAPH_OPTIONS
	);
	const [globalQuickStats, setGlobalQuickStats] = useState(null);
	const [viewGlobalIndex, setViewGlobalIndex] = useState(false);
	const [viewScrollToTopButton, setViewScrollToTopButton] = useState(false);
	const [startAfterColId, setStartAfterColId] = useState(null);
	const [loadingCollections, setLoadingCollections] = useState(false);
	const [loadingGlobalLoans, setLoadingGlobalLoans] = useState(false);
	const [loadingGlobalTheme, setLoadingGlobalTheme] = useState(false);

	useEffect(() => {
		if (!testMode) {
			window.addEventListener("scroll", trackScroll);

			//window.addEventListener("scroll", handleScroll);
			//if (windowDimensions.width > 1024) {
			window.addEventListener("scroll", handleScroll);
			// }
		}
	}, []);

	useEffect(() => {
		if (!search && viewGlobalIndex) {
			getGlobalLoans();
		}
	}, [viewGlobalIndex, loanPlatform]);

	const getGlobalLoans = async () => {
		if (!loadingGlobalLoans) {
			setLoadingGlobalLoans(true);
			const {
				newGlobalHistoryData,
				newGlobalHistoryOptions,
				newGlobalQuickStats,
			} = await fetchGlobalLoansHistory(theme);
			setGlobalGraphData(newGlobalHistoryData);
			setGlobalChartOptions(newGlobalHistoryOptions);
			setGlobalQuickStats(newGlobalQuickStats);
			setLoadingGlobalLoans(false);
		}
	};

	useEffect(() => {
		// Saves pageview to analytics
		if (search) {
			ReactGA.send({
				hitType: "pageview",
				page_title: "Search Page",
				search_query: search,
				page: window.location.pathname + window.location.search,
			});
		} else {
			ReactGA.send({
				hitType: "pageview",
				page_title: "Overview Loans Page",
				page: window.location.pathname + window.location.search,
			});
		}
	}, [search]);

	useEffect(() => {
		// Ensures collection list resets
		setStartAfterColId(null);
		getCollection(true);
	}, [search]);

	useEffect(() => {
		getCollection();
	}, [limit]);

	useEffect(() => {
		switchGlobalBarTheme();
	}, [theme]);

	const getCollection = async (resetList = false) => {
		if (!loadingCollections) {
			setLoadingCollections(true);
			let namesSearchList = [];

			// If a user made a search query, check algolia
			if (search) {
				namesSearchList = await searchCollectionsOnAlgolia(search, 12);
				setStartAfterColId(null);
			}

			const { colList, limitReached, startAfter } =
				await fetchMultipleCollections(
					search,
					namesSearchList,
					startAfterColId
				);

			setStartAfterColId(startAfter);

			if (collectionList.length > 0 && !resetList) {
				setCollectionList([...collectionList, ...colList]);
			} else {
				setCollectionList(colList);
			}

			setLimitCap(limitReached);
			if (limitReached) {
				// Removing scroll event listener, loaded in all collections
				//if (windowDimensions.width > 1024) {
				window.removeEventListener("scroll", handleScroll);
				//}
			}

			// If the user searched for a collection and
			// no results were found, log the event
			if (colList.length == 0 && search) {
				ReactGA.event({
					category: "search_result",
					action: "no_results_search",
					search_query: search,
					label: search + ", no results",
				});
			} else {
				ReactGA.event({
					category: "search_result",
					action: "found_results_search",
					search_query: search,
					label: search + ", " + colList.length + " results",
				});
			}
			setLoadingCollections(false);
		}
	};

	const handleScroll = useCallback(() => {
		const yPos = window.innerHeight + window.scrollY;
		if (yPos + 5 >= document.body.offsetHeight) {
			setLimit((limit) => limit + 3);
		}
	}, [limitCap]);

	const trackScroll = () => {
		if (window.scrollY > 400 && !viewScrollToTopButton) {
			setViewScrollToTopButton(true);
		} else if (window.scrollY <= 400) {
			setViewScrollToTopButton(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const switchGlobalBarTheme = async () => {
		if (!loadingGlobalTheme) {
			setLoadingGlobalTheme(true);
			if (globalGraphData != null) {
				const { newLoanHistoryData, newLoansHistoryChartOptions } =
					await adjustBarTheme(globalGraphData, globalChartOptions, theme);
				if (newLoanHistoryData) {
					setGlobalGraphData(newLoanHistoryData);
				}
				if (newLoansHistoryChartOptions) {
					setGlobalChartOptions(newLoansHistoryChartOptions);
				}
			}
			setLoadingGlobalTheme(false);
		}
	};

	return (
		<div>
			{viewScrollToTopButton && (
				<div
					className="fixed bottom-5 right-5 lg:bottom-10 lg:right-10 z-10000"
					style={{ zIndex: 10000 }}
				>
					<ScrollUpButton {...{ scrollToTop }} />
				</div>
			)}
			<div className="flex flex-col text-center justify-center items-center pt-2">
				<div className="flex flex-col lg:flex-row w-full justify-between items-center mb-2">
					<div className="lg:w-1/3"></div>
					<div className="lg:w-1/3">
						<h1 className="text-3xl font-cubano pt-6 text-dark-blue dark:text-white">
							Solana NFT Liquidations
						</h1>

						<h2 className="font-roboto text-md pt-4 italic pb-4 text-black dark:text-cloud-gray">
							View Solana NFT loans analytics across all major loan platforms.
						</h2>

						{search && (
							<p className="font-roboto text-md pt-4 italic pb-4 text-black dark:text-cloud-gray">
								{collectionList.length == 0
									? "No results found for: " + search
									: "Result(s) found " +
									  " for: " +
									  search +
									  " - " +
									  collectionList.length}
							</p>
						)}
					</div>
					<div className="lg:w-1/3 lg:flex lg:flex-wrap lg:items-center lg:justify-center font-roboto font-normal">
						<div className="lg:mx-6 mb-2 lg:mt-2" style={{ maxWidth: "200px" }}>
							<Select
								options={GRAPH_TYPES}
								onChange={(e) => {
									if (e.value != graphType.value) {
										setGraphType(e);
									}
								}}
								isSearchable={false}
								styles={
									theme === "dark" ? DARK_SELECT_STYLES : LIGHT_SELECT_STYLES
								}
								value={graphType}
								className="z-[20]"
							/>
						</div>
						<div className="lg:my-2" style={{ maxWidth: "250px" }}>
							<Select
								options={LOAN_PLATFORMS}
								onChange={(e) => {
									if (e.value != loanPlatform.value) {
										setLoanPlatform(e);
									}
								}}
								isSearchable={false}
								styles={
									theme === "dark" ? DARK_SELECT_STYLES : LIGHT_SELECT_STYLES
								}
								value={loanPlatform}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="flex flex-col text-center justify-center items-center pb-2">
				{!search && (
					<>
						<div className="flex flex-row items-center justify-center mb-2 mt-2 lg:mt-0">
							<h3 className="text-lg font-roboto font-bold text-dark-blue dark:text-cloud-gray">
								Global Leverage Index
							</h3>
							<a
								data-tooltip-id="my-tooltip"
								data-tooltip-content={
									"Global Leverage Index of Top 15 SOL Collections"
								}
								className="mr-1"
								style={{
									paddingLeft: "10px",
									paddingRight: "10px",
									paddingTop: "8px",
									paddingBottom: "8px",
								}}
							>
								<BsFillInfoCircleFill color="#818181 " size={12} />
							</a>
							<Switch
								checked={viewGlobalIndex}
								onChange={() => {
									setViewGlobalIndex(!viewGlobalIndex);
								}}
								onColor={"#e41e8e"}
								offColor={"#04004f"}
								checkedIcon={false}
								uncheckedIcon={false}
							/>
						</div>
						{viewGlobalIndex && (
							<GlobalLoansIndex
								{...{
									globalGraphData,
									globalChartOptions,
									globalQuickStats,
									theme,
								}}
							/>
						)}
					</>
				)}
				<div>
					{/** Collections display */}
					<div className="flex flex-row w-full lg:max-w-screen-2xl flex-wrap justify-center">
						{collectionList &&
							collectionList.length > 0 &&
							collectionList.map((col, index) => {
								return (
									<div className="flex flex-col md:px-10 pt-4 pb-6" key={index}>
										<CollectionItem
											collection={col}
											{...{ loanPlatform, graphType, theme, setTheme }}
										/>
									</div>
								);
							})}
					</div>
					{/** windowDimensions.width > 1024 && */}
					{!limitCap && collectionList.length > 0 && (
						<button
							onClick={() => {
								getCollection();
								//setLimit(limit + 3);
							}}
							className="pb-12 px-6"
						>
							<p className="text-sm font-roboto font-semibold text-pink underline underline-offset-2">
								Load More
							</p>
						</button>
					)}

					{/*windowDimensions.width <= 1024 && collectionList.length > 0 && (
						<p className="text-sm px-12 pb-24 font-roboto font-semibold text-pink">
							Search for More Collections using the Search Bar at the Top.
						</p>
					)*/}
				</div>
			</div>
		</div>
	);
};

export default OverviewLoansPage;
