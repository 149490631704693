import React from "react";
import SharkyLogo from "../../assets/images/sharky.png";
import FraktLogo from "../../assets/images/frakt.png";
import CitrusLogo from "../../assets/images/citrus.png";
import RainLogo from "../../assets/images/rain.png";

import { BsArrowUpRight, BsArrowDownRight } from "react-icons/bs";

export const MarketplaceBox = ({ elem, index, globalVol }) => {
	return (
		<div
			className={`border border-gray dark:border-[#313237] rounded-md mt-2 mb-1 mx-1 w-full max-w-[475px]`}
			key={index}
		>
			<div className="flex flex-row h-14 w-full max-w-[475px] lg:w-[475px] justify-between items-center text-left pl-2 lg:px-4 pt-1 dark:border-[#313237]">
				<div className="w-2/5 md:w-1/3 flex flex-row items-center">
					<p className="text-sm font-roboto capitalize font-medium text-dark-gray dark:text-[#C5C5C5]">
						{index + 1}
					</p>
					<img
						src={
							elem.marketplace == "sharky"
								? SharkyLogo
								: elem.marketplace == "citrus"
								? CitrusLogo
								: elem.marketplace == "frakt"
								? FraktLogo
								: RainLogo
						}
						class="object-contain h-6 w-6 mx-2"
						alt={`${elem.marketplace} logo`}
					/>
					<p className="text-lg font-roboto capitalize font-medium  dark:text-white w-20">
						{"  "}
						{elem.marketplace != "frakt" ? elem.marketplace : "banx"}
					</p>
				</div>

				<div className="flex flex-col w-1/3 md:w-1/4">
					<p className="text-md font-roboto font-medium text-black dark:text-white">
						{Number(elem.totalVolume?.toFixed(0))?.toLocaleString("en-US")} SOL
					</p>
					<div className="flex flex-row items-center">
						{elem?.volChange > 0 && (
							<BsArrowUpRight size={10} className="mr-1 text-green-500" />
						)}
						{elem?.volChange < 0 && (
							<BsArrowDownRight size={10} className="mr-1 text-red-500" />
						)}
						<p
							className={`text-xs font-roboto capitalize font-medium ${
								elem?.volChange > 0
									? `text-green-500`
									: elem?.volChange < 0
									? `text-red-500`
									: "text-dark-gray"
							}`}
						>
							{!isNaN(elem?.volChange)
								? Math.abs(Number(elem?.volChange?.toFixed(2))) + "%"
								: "—"}
						</p>
					</div>
				</div>

				<div className="flex flex-col w-1/4 md:w-1/5 lg:w-1/4">
					<p className="text-md font-roboto font-medium text-black dark:text-white">
						{!isNaN(globalVol)
							? ((elem.totalVolume / globalVol) * 100)?.toFixed(2)
							: "—"}
						%
					</p>
					<div className="flex flex-row items-center">
						{elem?.marketDominanceChange > 0 && (
							<BsArrowUpRight size={10} className="mr-1 text-green-500" />
						)}
						{elem?.marketDominanceChange < 0 && (
							<BsArrowDownRight size={10} className="mr-1 text-red-500" />
						)}
						<p
							className={`text-xs font-roboto capitalize font-medium ${
								elem?.marketDominanceChange > 0
									? `text-green-500`
									: elem?.marketDominanceChange < 0
									? `text-red-500`
									: "text-dark-gray"
							}`}
						>
							{!isNaN(elem?.marketDominanceChange)
								? Math.abs(Number(elem?.marketDominanceChange?.toFixed(2))) +
								  "%"
								: "—"}
						</p>
					</div>
				</div>

				<div className="w-1/5 md:w-1/4 hidden md:flex flex-col text-left">
					<p className="text-xs font-roboto text-dark-gray dark:text-[#C5C5C5]">
						{elem?.totalLoans ? elem.totalLoans : "—"} Loans
					</p>
					<p className="text-xs font-roboto text-dark-gray dark:text-[#C5C5C5]">
						{elem?.uniqueBorrowers ? elem.uniqueBorrowers : "—"} Borrowers
					</p>
				</div>
			</div>
			<div className="flex flex-row md:hidden items-center pb-1 pr-4 justify-center text-left">
				<p className="text-xs font-roboto mr-6 text-dark-gray dark:text-[#C5C5C5]">
					{elem?.totalLoans ? elem.totalLoans : "—"} Loans
				</p>
				<p className="text-xs font-roboto text-dark-gray dark:text-[#C5C5C5]">
					{elem?.uniqueBorrowers ? elem.uniqueBorrowers : "—"} Borrowers
				</p>
			</div>
		</div>
	);
};

export default MarketplaceBox;
