import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import MbbLogo from "../../assets/images/monkey_baby_business_logo.png";
import ReactGA from "react-ga4";
import { adjustMarketplaceStatsBarTheme } from "../../services/features/chart-theme-service";
import { MARKETPLACE_STATS_BAR_OPTIONS } from "../../values/constants";
import {
	fetchTodaysMarketplaceStats,
	fetchHistoricalMarketplaceStats,
} from "../../services/features/marketplace-stats-service";
import html2canvas from "html2canvas";
import { BsDownload } from "react-icons/bs";
import { MarketplaceBox } from "./MarketplaceBox";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Bars } from "react-loader-spinner";
import { fetchWeeklyMarketplaceStats } from "../../services/features/marketplace-stats-service";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export const MarketplaceStatsPage = ({ theme }) => {
	// 24H Volume
	const [marketplaceDailyStatsList, setMarketplaceDailyStatsList] =
		useState(null);
	const [globalVol, setGlobalVol] = useState(null);

	// Weekly / 7D Volume
	const [marketplaceWeeklyStatsList, setMarketplaceWeeklyStatsList] =
		useState(null);
	const [weeklyGlobalVol, setWeeklyGlobalVol] = useState(null);
	const [weeklyDaysOfData, setWeeklyDaysOfData] = useState(7);

	// Historical Daily Volume by Marketplace - Bar Chart
	const [marketplaceDailyBarData, setMarketplaceDailyBarData] = useState(null);
	const [marketplaceDailyBarOptions, setMarketplaceDailyBarOptions] = useState(
		MARKETPLACE_STATS_BAR_OPTIONS
	);

	useEffect(() => {
		window.scrollTo(0, 0);
		getMarketplaceData();
		getDailyLoanVolume();
	}, []);

	/** Used to get historical daily volume for bar chart */
	const getDailyLoanVolume = async () => {
		const { newBarData, newBarOptions } = await fetchHistoricalMarketplaceStats(
			marketplaceDailyBarOptions,
			theme
		);
		setMarketplaceDailyBarData(newBarData);
		if (newBarOptions) {
			setMarketplaceDailyBarOptions(newBarOptions);
		}
	};

	/** Used to get marketplace 24h and 7d stats */
	const getMarketplaceData = async () => {
		// 24H stats
		const { statsList, globalVolume } = await fetchTodaysMarketplaceStats();
		setMarketplaceDailyStatsList(statsList);
		setGlobalVol(globalVolume);

		// 7D stats
		const { weeklyStatsList, daysOfData, weeklyGlobalVolume } =
			await fetchWeeklyMarketplaceStats();
		setWeeklyDaysOfData(daysOfData);
		setMarketplaceWeeklyStatsList(weeklyStatsList);
		setWeeklyGlobalVol(weeklyGlobalVolume);

		// Saves pageview to analytics
		ReactGA.send({
			hitType: "pageview",
			page_title: "Marketplace Stats Page",
			page: window.location.pathname,
		});
	};

	useEffect(() => {
		if (theme) {
			switchTheme();
		}
	}, [theme]);

	const switchTheme = async () => {
		if (marketplaceDailyBarData != null) {
			const { newGraphData, newChartOptions } =
				await adjustMarketplaceStatsBarTheme(
					marketplaceDailyBarData,
					marketplaceDailyBarOptions,
					theme
				);
			if (newGraphData) {
				setMarketplaceDailyBarData(newGraphData);
			}

			if (newChartOptions) {
				setMarketplaceDailyBarOptions(newChartOptions);
			}
		}
	};

	/** Used to build the chart into a canvas and
	 * download the chart to the user's device */
	const exportChart = async (imageFileName, elementId) => {
		try {
			const el = document.getElementById(elementId);
			const canvas = await html2canvas(el, {
				useCORS: true,
				scale: 5,
				backgroundColor: theme === "light" ? "#fff" : "#1E1F25",
			});
			const image = canvas.toDataURL("image/png", 1.0);
			downloadImage(image, imageFileName);
		} catch (error) {}
	};
	/** Downloads the image to the user's device */
	const downloadImage = (blob, fileName) => {
		const fakeLink = window.document.createElement("a");
		fakeLink.style = "display:none;";
		fakeLink.download = fileName;
		fakeLink.href = blob;

		document.body.appendChild(fakeLink);
		fakeLink.click();
		document.body.removeChild(fakeLink);
		fakeLink.remove();
	};

	return (
		<div className="bg-white dark:bg-light-black h-screen">
			<Helmet>
				<title>{`NFT Loan Marketplace Stats - Monkey Baby Business`}</title>
				<meta charSet="utf-8" />
				<meta
					name="description"
					content={`View Solana NFT loan marketplace stats and analytics including SharkyFi, Citrus and Banx. Powered by Monkey Baby Business Loans Analytics.`}
				/>
			</Helmet>

			<div className="flex flex-col justify-center items-center text-center px-8 mt-4 w-full">
				<h1 className="text-3xl font-cubano pt-6 text-dark-blue dark:text-white">
					Loan Marketplace Stats
				</h1>

				<h2 className="font-roboto text-md pt-4 italic pb-6 text-black dark:text-cloud-gray">
					View the leaderboards of the top Solana NFT loan marketplaces by
					volume.
				</h2>

				<div className="flex flex-col lg:flex-row lg:w-full lg:justify-center">
					<div className="flex flex-col mb-8 lg:mr-12 lg:mb-0">
						<div className="flex flex-row justify-around items-center mb-2 px-4 lg:px-0">
							<p className="text-xl leading-snug font-roboto font-bold text-start lg:text-center md:pr-4 text-dark-blue dark:text-white">
								24H Loan Volume
								{globalVol &&
									": " +
										Number(globalVol?.toFixed(0))?.toLocaleString() +
										" SOL"}
							</p>
							<img
								src={MbbLogo}
								class="object-contain h-12 w-24"
								alt="Monkey Baby Business logo"
							/>
						</div>
						{marketplaceDailyStatsList ? (
							marketplaceDailyStatsList.map((elem, index) => {
								return (
									<MarketplaceBox {...{ elem, index, globalVol }} key={index} />
								);
							})
						) : (
							<div
								className="flex justify-center items-center"
								style={{ minHeight: 200 }}
							>
								<Bars
									height="50"
									width="50"
									color="#e41e8e"
									ariaLabel="bars-loading"
									visible={true}
								/>
							</div>
						)}
					</div>

					<div className="flex flex-col">
						<div className="flex flex-row justify-around items-center mb-2 px-4 lg:px-0">
							<p className="text-xl leading-snug font-roboto text-start lg:text-center font-bold md:pr-4 text-dark-blue dark:text-white">
								7D Loan Volume
								{weeklyGlobalVol &&
									": " +
										Number(weeklyGlobalVol.toFixed(0))?.toLocaleString() +
										" SOL"}
							</p>
							<img
								src={MbbLogo}
								class="object-contain h-12 w-24"
								alt="Monkey Baby Business logo"
							/>
						</div>
						{marketplaceWeeklyStatsList ? (
							marketplaceWeeklyStatsList.map((elem, index) => {
								return (
									<MarketplaceBox
										{...{ elem, index }}
										globalVol={weeklyGlobalVol}
										key={index}
									/>
								);
							})
						) : (
							<div
								className="flex justify-center items-center"
								style={{ minHeight: 200 }}
							>
								<Bars
									height="50"
									width="50"
									color="#e41e8e"
									ariaLabel="bars-loading"
									visible={true}
								/>
							</div>
						)}
					</div>
				</div>

				<div className="flex flex-col mt-10 mb-24 justify-center align-center">
					<div className="flex flex-row justify-around items-center mb-2 px-4 lg:px-0">
						<p className="text-xl leading-snug font-roboto text-start lg:text-center font-bold md:pr-4 text-dark-blue dark:text-white">
							Daily Loan Volume by Marketplace
						</p>
						<img
							src={MbbLogo}
							class="object-contain h-12 w-24"
							alt="Monkey Baby Business logo"
						/>
					</div>
					{marketplaceDailyBarData ? (
						<div
							id="marketplace-stats-barchart"
							style={{
								position: "relative",
								width: "95%",
								height: "100%",
								// minHeight: "65vh",
								display: "flex",
								justifyContent: "center",
								paddingBottom: 28,
								maxWidth: "1000px",
								margin: "auto",
								zIndex: 10,
							}}
						>
							<Bar
								options={marketplaceDailyBarOptions}
								data={marketplaceDailyBarData}
								height={"275"}
								id="barStatsChart"
								style={{ zIndex: 20 }}
							/>

							<img
								src={MbbLogo}
								width={140}
								height={55}
								alt="logo"
								style={{
									position: "absolute",
									opacity: 0.125,
									top: "50%",
									left: "50%",
									zIndex: -1,
									transform: "translate(-35%, -25%)",
								}}
							/>
							<div
								style={{
									position: "absolute",
									zIndex: -1,
									bottom: "2%",
								}}
							>
								<p className="font-roboto text-pink text-xs pl-[24px]">
									Source: https://loans.monkeybaby.business
								</p>
							</div>
						</div>
					) : (
						<div
							className="flex justify-center items-center"
							style={{ minHeight: 275 }}
						>
							<Bars
								height="50"
								width="50"
								color="#e41e8e"
								ariaLabel="bars-loading"
								visible={true}
							/>
						</div>
					)}

					<div
						className="flex flex-row w-full justify-end px-4 mt-2 pb-2"
						style={{
							maxWidth: "1000px",
						}}
					>
						<button
							className="flex justify-start items-center ml-4"
							style={{ marginTop: -2 }}
							onClick={() => {
								exportChart(
									"marketplace-stats-chart.png",
									"marketplace-stats-barchart"
								);
							}}
						>
							<BsDownload color="#e41e8e" size={12} />
							<p className="text-xs font-roboto text-pink ml-1">
								Download Chart
							</p>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MarketplaceStatsPage;
