import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import Switch from "react-switch";
import { BsFillMoonFill, BsFillSunFill } from "react-icons/bs";

import {
	TOOLS_DROPDOWN,
	MONKEY_BABY_DROPDOWN,
	LOANS_DROPDOWN,
} from "../../values/dropdowns";

import DropdownMobileMenu from "./DropdownMobileMenu";

export const MobileFullscreenMenu = ({
	theme,
	setTheme,
	setSearchQuery,
	setSearchResults,
	setViewMobileMenu,
}) => {
	return (
		<div className="fixed w-screen h-screen overflow-y-auto pb-24 z-[100] lg:hidden bg-white dark:bg-light-black">
			<div className="flex flex-col justify-center items-center pt-6 pb-20 w-full">
				<div className="flex flex-row justify-between w-full px-6 mb-8">
					<button
						onClick={() => {
							setViewMobileMenu(false);
						}}
						className="flex lg:hidden mr-4 text-dark-blue dark:text-white"
					>
						<IoClose size={32} />
					</button>
					<Switch
						checked={theme == "light"}
						onChange={() => {
							if (theme === "dark") {
								localStorage.setItem("theme", "light");
								setTheme("light");
								return;
							} else {
								localStorage.setItem("theme", "dark");
								setTheme("dark");
							}
						}}
						onColor={"#e41e8e"}
						offColor={"#04004f"}
						checkedIcon={false}
						uncheckedIcon={false}
						handleDiameter={25}
						width={65}
						height={30}
						checkedIcon={
							<div
								style={{
									height: "30px",
									width: "32px",
									alignItems: "center",
									justifyContent: "center",
									display: "flex",
								}}
							>
								<BsFillSunFill color={"#fff"} size={16} />
							</div>
						}
						uncheckedIcon={
							<div
								style={{
									height: "30px",
									width: "32px",
									alignItems: "center",
									justifyContent: "center",
									display: "flex",
								}}
							>
								<BsFillMoonFill color={"#fff"} size={16} />
							</div>
						}
					/>
				</div>

				<DropdownMobileMenu
					title={"Loans"}
					dropdownItems={LOANS_DROPDOWN}
					externalLinks={false}
					{...{ setSearchQuery, setSearchResults, setViewMobileMenu }}
				/>

				<DropdownMobileMenu
					title={"Monkey Babies"}
					dropdownItems={MONKEY_BABY_DROPDOWN}
					externalLinks={true}
					{...{ setSearchQuery, setSearchResults, setViewMobileMenu }}
				/>

				<DropdownMobileMenu
					title={"Tools"}
					dropdownItems={TOOLS_DROPDOWN}
					externalLinks={true}
					{...{ setSearchQuery, setSearchResults, setViewMobileMenu }}
				/>
			</div>
		</div>
	);
};

export default MobileFullscreenMenu;
