import React from "react";

export const NotFoundPage = () => {
	return (
		<div className="pb-24 h-screen">
			<div className="flex flex-col justify-center items-center pt-4">
				<h1 className="text-5xl font-cubano font-bold pt-6 text-dark-blue dark:text-white">
					404
				</h1>
				<h1 className="text-3xl font-cubano font-bold pt-4 text-dark-blue dark:text-white">
					This page is lost.
				</h1>
				<p className="text-lg pt-4 font-roboto text-black dark:text-cloud-gray">
					We can't find the page you were looking for.
				</p>
			</div>
		</div>
	);
};

export default NotFoundPage;
