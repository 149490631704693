import React from "react";
import { useNavigate } from "react-router-dom";

export const SearchResultsList = ({
	searchResults,
	setSearchResults,
	setSearchQuery,
}) => {
	const navigate = useNavigate();

	const clickSearchItem = (magicEdenSlug) => {
		setSearchResults([]);
		setSearchQuery("");
		navigate(`/loans/${magicEdenSlug}`);
	};

	return (
		<div
			className="absolute border dark:border-dark-gray rounded-xl bg-white dark:bg-light-black z-50"
			style={{ width: "300px" }}
		>
			{searchResults &&
				searchResults?.length > 0 &&
				searchResults.map((searchItem, index) => {
					return (
						<button
							onClick={() => {
								clickSearchItem(searchItem?.magicEdenSlug);
							}}
							className={`flex flex-row w-full items-center justify-start p-2 ${
								index < searchResults?.length - 1
									? `border-b border-light-gray dark:border-dark-gray`
									: ``
							}`}
							key={index}
						>
							<img
								src={
									searchItem?.imageUrl
										? "https://res.cloudinary.com/dsssgubl9/image/fetch/w_200,h_200,c_fill/" +
										  encodeURI(searchItem?.imageUrl)
										: "https://res.cloudinary.com/dsssgubl9/image/fetch/w_200,h_200,c_fill/" +
										  encodeURI(
												"https://creator-hub-prod.s3.us-east-2.amazonaws.com/unknownabstracts_pfp_1650063070880.png"
										  )
								}
								width={40}
								height={40}
								alt="search result NFT collection image"
								className="rounded-full mr-3"
							/>
							<div className="flex flex-col text-start justify-start items-start">
								<p className="text-md font-roboto font-bold text-dark-blue dark:text-white">
									{searchItem?.name}
								</p>
								<p className="text-xs font-roboto font-medium text-black dark:text-dark-gray">
									{searchItem?.supply} items
								</p>
							</div>
						</button>
					);
				})}
		</div>
	);
};

export default SearchResultsList;
