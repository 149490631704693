import React from "react";
import { Routes, Route } from "react-router-dom";
import LoanDetailsPage from "../components/loan-details/LoanDetailsPage";
import NotFoundPage from "../components/not-found/NotFoundPage";
import OverviewLoansPage from "../components/overview-loans/OverviewLoansPage";
import MarketplaceStatsPage from "../components/marketplace-stats/MarketplaceStatsPage";

const AppRoutes = ({ theme, setTheme }) => {
	return (
		<Routes>
			<Route
				path="/"
				element={<OverviewLoansPage {...{ theme, setTheme }} />}
			/>
			<Route
				path="/loans/:magicEdenSlug"
				element={<LoanDetailsPage {...{ theme, setTheme }} />}
			/>
			<Route
				path="/marketplace_stats"
				element={<MarketplaceStatsPage {...{ theme, setTheme }} />}
			/>
			<Route path="*" element={<NotFoundPage {...{ theme, setTheme }} />} />
		</Routes>
	);
};

export default AppRoutes;
