import React from "react";
import ScrollUpArrow from "../../assets/images/scroll-up-arrow.png";

export const ScrollUpButton = ({ scrollToTop }) => {
	return (
		<button
			onClick={() => {
				scrollToTop();
			}}
		>
			<img src={ScrollUpArrow} width={60} height={60} alt="scroll up arrow" />
		</button>
	);
};

export default ScrollUpButton;
