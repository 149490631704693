import React from "react";

export const StatsBox = ({
	statsLabel = "",
	statsValue = "-",
	floorPrice = false,
}) => {
	return (
		<div
			className={`border border-gray dark:border-[#313237] rounded-md mb-1 mx-1 py-1`}
			style={{ width: "105px" }}
		>
			<p
				className="text-xs font-roboto text-dark-gray dark:text-[#C5C5C5]"
				style={floorPrice ? { color: "#DB7130" } : null}
			>
				{statsLabel}
			</p>
			<p
				className="text-sm font-roboto font-medium text-black dark:text-white"
				style={floorPrice ? { color: "#DB7130" } : null}
			>
				{statsValue?.toLocaleString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
			</p>
		</div>
	);
};

export default StatsBox;
