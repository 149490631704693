import React from "react";
import MbbLogo from "../../assets/images/monkey_baby_business_logo.png";
import { Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { Bars } from "react-loader-spinner";
import { GlobalStatsList } from "../overview-loans/GlobalStatsList";
import html2canvas from "html2canvas";
import { BsDownload } from "react-icons/bs";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip,
	Legend,
	Title,
} from "chart.js";

ChartJS.register(
	LinearScale,
	CategoryScale,
	PointElement,
	LineElement,
	Tooltip,
	Legend,
	Title,
	annotationPlugin
);

export const GlobalLoansIndex = ({
	globalGraphData,
	globalChartOptions,
	globalQuickStats,
	theme,
}) => {
	/** Used to build the chart into a canvas and
	 * download the chart to the user's device */
	const exportChart = async (imageFileName, elementId) => {
		try {
			const el = document.getElementById(elementId);
			const canvas = await html2canvas(el, {
				useCORS: true,
				scale: 5,
				backgroundColor: theme === "light" ? "#fff" : "#1E1F25",
			});
			const image = canvas.toDataURL("image/png", 1.0);
			downloadImage(image, imageFileName);
		} catch (error) {}
	};
	/** Downloads the image to the user's device */
	const downloadImage = (blob, fileName) => {
		const fakeLink = window.document.createElement("a");
		fakeLink.style = "display:none;";
		fakeLink.download = fileName;

		fakeLink.href = blob;

		document.body.appendChild(fakeLink);
		fakeLink.click();
		document.body.removeChild(fakeLink);

		fakeLink.remove();
	};

	return (
		<div className="flex flex-col py-2 w-full">
			<div className="flex flex-col justify-center items-center">
				<GlobalStatsList quickStats={globalQuickStats} />
			</div>
			{globalGraphData ? (
				<>
					<div className="pb-4">
						<div
							style={{
								position: "relative",
								width: "100%",
								height: "100%",
								paddingHorizontal: "12px",
								display: "flex",
								justifyContent: "center",
								maxWidth: "1000px",
								margin: "auto",
								zIndex: 10,
							}}
							id="global-leverage-chart"
						>
							<Line
								options={globalChartOptions}
								data={globalGraphData}
								height={"100%"}
								width={"100%"}
								style={{
									maxWidth: "1000px",
									maxHeight: "450px",
									paddingBottom: "24px",
								}}
							/>

							<img
								src={MbbLogo}
								width={200}
								height={100}
								alt="logo"
								style={{
									position: "absolute",
									opacity: 0.125,
									top: "50%",
									left: "50%",
									zIndex: -1,
									transform: "translate(-33%, -33%)",
								}}
							/>
							<div
								id={"details-stamp"}
								style={{
									position: "absolute",
									zIndex: -1,
									bottom: "0%",
								}}
							>
								<p
									className="font-roboto text-pink"
									style={{
										fontSize: 10,
										paddingLeft: 24,
									}}
								>
									Source: https://loans.monkeybaby.business
								</p>
							</div>
						</div>
					</div>
					<div
						className="flex flex-row w-full justify-end"
						style={{ paddingRight: "15%" }}
					>
						<button
							className="flex items-center"
							style={{ marginTop: -2 }}
							onClick={() => {
								exportChart(
									"global-mbb-leverage-chart.png",
									"global-leverage-chart"
								);
							}}
						>
							<BsDownload color="#e41e8e" size={12} />
							<p className="text-xs font-roboto text-pink ml-1">
								Download Chart
							</p>
						</button>
					</div>
				</>
			) : (
				<div
					className="flex justify-center items-center"
					style={{ minHeight: 175 }}
				>
					<Bars
						height="50"
						width="50"
						color="#e41e8e"
						ariaLabel="bars-loading"
						visible={true}
					/>
				</div>
			)}
		</div>
	);
};

export default GlobalLoansIndex;
