import React from "react";
import StatsBox from "./StatsBox";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

export const GlobalStatsList = ({ quickStats }) => {
	return (
		<div className="flex flex-col">
			<table className="w-full mb-2 text-center text-sm font-roboto text-black">
				<tbody>
					<tr className="flex flex-wrap justify-center">
						<td>
							<a
								data-tooltip-id="my-tooltip"
								data-tooltip-content={
									"Weighted Floor Price of Top 15 SOL Collections"
								}
							>
								<StatsBox
									statsLabel="Floor Price"
									statsValue={quickStats?.floorPrice?.toFixed(2)}
								/>
							</a>
						</td>
						<td>
							<a
								data-tooltip-id="my-tooltip"
								data-tooltip-content={"Weighted LTV of Top 15 SOL Collections"}
							>
								<StatsBox statsLabel="LTV" statsValue={quickStats?.ltv} />
							</a>
						</td>
						<td>
							<a
								data-tooltip-id="my-tooltip"
								data-tooltip-content={"Total TVL of Top 15 SOL Collections"}
							>
								<StatsBox statsLabel="TVL" statsValue={quickStats?.tvl} />
							</a>
						</td>
						<td>
							<a
								data-tooltip-id="my-tooltip"
								data-tooltip-content={
									"Total Foreclosable of Top 15 SOL Collections"
								}
							>
								<StatsBox
									statsLabel="Foreclosable"
									statsValue={quickStats?.forecloseable}
								/>
							</a>
						</td>
					</tr>
				</tbody>
			</table>
			<Tooltip id="my-tooltip" className="font-roboto font-normal" />
		</div>
	);
};

export default GlobalStatsList;
