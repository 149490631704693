import React from "react";
import { useState, useEffect } from "react";
import {
	INITIAL_GRAPH_OPTIONS,
	INITIAL_LOAN_HISTORY_GRAPH_OPTIONS,
} from "../../values/constants";
import {
	adjustScatterTheme,
	adjustBarTheme,
} from "../../services/features/chart-theme-service";
import MbbLogo from "../../assets/images/monkey_baby_business_logo.png";
import { Scatter, Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { BsGraphUp, BsDownload, BsFillInfoCircleFill } from "react-icons/bs";
import StatsList from "./StatsList";
import { NavLink } from "react-router-dom";
// import { Bars } from "react-loader-spinner";
import { fetchLoansByCollection } from "../../services/features/loans-service";
import { fetchLoansHistoryByCollection } from "../../services/features/loans-history-service";

/** Chart Image Saving */
import html2canvas from "html2canvas";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip,
	Legend,
	Title,
	BarElement,
	LineController,
	BarController,
} from "chart.js";

import { Tooltip as ReactTooltip } from "react-tooltip";

ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Tooltip,
	Legend,
	Title,
	annotationPlugin,
	LineController,
	BarController
);

export const CollectionItem = ({
	collection,
	loanPlatform,
	graphType,
	chartHeight = "275",
	theme,
}) => {
	// Components each collection
	const [graphData, setGraphData] = useState(null);
	const [quickStats, setQuickStats] = useState(null);
	const [chartOptions, setChartOptions] = useState(INITIAL_GRAPH_OPTIONS);
	const [loansHistoryData, setLoansHistoryData] = useState(null);
	const [loansHistoryChartOptions, setLoansHistoryChartOptions] = useState(
		INITIAL_LOAN_HISTORY_GRAPH_OPTIONS
	);
	const [loadingLoans, setLoadingLoans] = useState(false);
	const [loadingLoansHistory, setLoadingLoansHistory] = useState(false);
	const [loadingTheme, setLoadingTheme] = useState(false);

	useEffect(() => {
		if (collection != null && collection?.name) {
			getLoans();
		}
	}, [collection, loanPlatform]);

	useEffect(() => {
		switchTheme();
	}, [theme]);

	useEffect(() => {
		if (collection != null && collection?.helloMoonCollectionId) {
			getLoansHistory();
		}
	}, [collection, quickStats]);

	const getLoansHistory = async () => {
		if (!loadingLoansHistory) {
			setLoadingLoansHistory(true);
			const { newHistoryData, newHistoryOptions } =
				await fetchLoansHistoryByCollection(
					collection,
					loansHistoryChartOptions,
					quickStats,
					theme
				);
			setLoansHistoryData(newHistoryData);
			setLoansHistoryChartOptions(newHistoryOptions);
			setLoadingLoansHistory(false);
		}
	};

	const getLoans = async () => {
		if (!loadingLoans) {
			setLoadingLoans(true);
			const { newQuickStats, newGraphData, newChartOptions } =
				await fetchLoansByCollection(
					collection,
					loanPlatform.value,
					chartOptions,
					theme
				);
			if (newChartOptions) {
				setChartOptions(newChartOptions);
			}
			setQuickStats(newQuickStats);
			setGraphData(newGraphData);
			setLoadingLoans(false);
		}
	};

	/** Used to build the chart into a canvas and
	 * download the chart to the user's device */
	const exportChart = async (imageFileName, elementId) => {
		try {
			document.getElementById(elementId + "-stamp").style = "display:flex;";

			const el = document.getElementById(elementId);
			const canvas = await html2canvas(el, {
				useCORS: true,
				scale: 5,
				backgroundColor: theme === "light" ? "#fff" : "#1E1F25",
			});
			const image = canvas.toDataURL("image/png", 1.0);
			downloadImage(image, imageFileName, elementId);
		} catch (error) {}
	};
	/** Downloads the image to the user's device */
	const downloadImage = (blob, fileName, elementId) => {
		const fakeLink = window.document.createElement("a");
		fakeLink.style = "display:none;";
		fakeLink.download = fileName;

		fakeLink.href = blob;

		document.body.appendChild(fakeLink);
		fakeLink.click();
		document.body.removeChild(fakeLink);

		fakeLink.remove();
		document.getElementById(elementId + "-stamp").style = "display:none;";
	};

	const switchTheme = async () => {
		if (!loadingTheme) {
			setLoadingTheme(true);
			if (graphData != null) {
				const { newGraphData, newChartOptions } = await adjustScatterTheme(
					graphData,
					chartOptions,
					theme
				);
				if (newGraphData) {
					setGraphData(newGraphData);
				}
				if (newChartOptions) {
					setChartOptions(newChartOptions);
				}
			}

			if (loansHistoryData != null) {
				const { newLoanHistoryData, newLoansHistoryChartOptions } =
					await adjustBarTheme(
						loansHistoryData,
						loansHistoryChartOptions,
						theme
					);

				if (newLoanHistoryData) {
					setLoansHistoryData(newLoanHistoryData);
				}
				if (newLoansHistoryChartOptions) {
					setLoansHistoryChartOptions(newLoansHistoryChartOptions);
				}
			}
			setLoadingTheme(false);
		}
	};

	return (
		<div className="flex flex-col">
			<div id={collection?.name + "-loans-chart"} className="p-2">
				<StatsList {...{ collection, quickStats }} overviewPage={true} />
				{graphData || loansHistoryData ? (
					<>
						{graphType.value != "line" && (
							<div>
								<div style={{ position: "relative", zIndex: 20 }}>
									<a
										data-tooltip-id="underwater-tooltip"
										data-tooltip-content={
											"Citrus provides rarity-based loans which may impact underwater loans. Hover over plots for more info."
										}
										style={{
											position: "absolute",
											top: "14.5%",
											right: "4%",
											zIndex: 21,
											transform: "translate(-4%, -14.5%)",
											paddingLeft: "12px",
											paddingRight: "12px",
											paddingTop: "8px",
											paddingBottom: "8px",
										}}
									>
										{" "}
										<BsFillInfoCircleFill
											className="text-dark-gray"
											size={12}
										/>
									</a>
									<Scatter
										options={chartOptions}
										data={graphData}
										height={chartHeight}
										id="scatterChart"
										style={{ zIndex: 20 }}
										// className={
										// 	loadingLoans
										// 		? "animate-pulse opacity-10"
										// 		: { undefined }
										// }
									/>

									<img
										src={MbbLogo}
										width={140}
										height={55}
										alt="logo"
										style={{
											position: "absolute",
											opacity: 0.125,
											top: "50%",
											left: "50%",
											zIndex: -10,
											transform: "translate(-35%, -25%)",
										}}
									/>
								</div>

								<div
									className="flex w-full justify-center ml-4"
									style={{ display: "none" }}
									id={collection?.name + "-loans-chart-stamp"}
								>
									<p
										className="font-roboto text-pink"
										style={{ fontSize: 8, paddingBottom: 4, marginTop: -6 }}
									>
										Source: https://loans.monkeybaby.business
									</p>
								</div>
							</div>
						)}
					</>
				) : (
					<div
						className="flex justify-center items-center"
						style={{ minHeight: 255 }}
					>
						<p className="font-roboto text-pink text-md">Loading...</p>
						{/* <Bars
							height="50"
							width="50"
							color="#e41e8e"
							ariaLabel="bars-loading"
							visible={true}
						/> */}
					</div>
				)}
			</div>
			<ReactTooltip
				id="underwater-tooltip"
				className="dark:!bg-white dark:!text-black font-roboto font-medium z-[23]"
				style={{
					whiteSpace: "pre-line",
					width: "400px",
					maxWidth: "90%",
				}}
			/>

			{graphType.value != "line" && (
				<div className="flex flex-row w-full justify-between mb-2">
					<NavLink
						to={"/loans/" + collection?.magicEdenSlug}
						className="flex justify-start items-center ml-4"
						style={{ marginTop: -2 }}
					>
						<BsGraphUp color="#e41e8e" size={12} />
						<p className="text-xs font-roboto text-pink ml-1">Expand Chart</p>
					</NavLink>

					<button
						className="flex justify-start items-center ml-4"
						style={{ marginTop: -2 }}
						onClick={() => {
							exportChart(
								collection?.name + "-mbb-loans-chart.png",
								collection?.name + "-loans-chart"
							);
						}}
					>
						<BsDownload color="#e41e8e" size={12} />
						<p className="text-xs font-roboto text-pink ml-1">Download Chart</p>
					</button>
				</div>
			)}

			{graphType.value != "scatter" && (
				<div id={collection?.name + "-leverage-chart"} className="pb-2">
					{loansHistoryData && (
						<div style={{ position: "relative", zIndex: 10 }}>
							<Line
								options={loansHistoryChartOptions}
								data={loansHistoryData}
								height={225}
							/>
							<img
								src={MbbLogo}
								width={140}
								height={55}
								alt="logo"
								style={{
									position: "absolute",
									opacity: 0.125,
									top: "50%",
									left: "50%",
									zIndex: -1,
									transform: "translate(-33%, -33%)",
								}}
							/>
						</div>
					)}
					<div
						className="flex w-full justify-center ml-4"
						style={{ display: "none" }}
						id={collection?.name + "-leverage-chart-stamp"}
					>
						<p
							className="font-roboto text-pink"
							style={{ fontSize: 8, paddingBottom: 4, marginTop: -6 }}
						>
							Source: https://loans.monkeybaby.business
						</p>
					</div>
				</div>
			)}

			{graphType.value != "scatter" && (
				<div className="flex flex-row w-full justify-between">
					<NavLink
						to={"/loans/" + collection?.magicEdenSlug}
						className="flex justify-start items-center ml-4"
						style={{ marginTop: -2 }}
					>
						<BsGraphUp color="#e41e8e" size={12} />
						<p className="text-xs font-roboto text-pink ml-1">Expand Chart</p>
					</NavLink>

					<button
						className="flex justify-start items-center ml-4"
						style={{ marginTop: -2 }}
						onClick={() => {
							exportChart(
								collection?.name + "-mbb-leverage-chart.png",
								collection?.name + "-leverage-chart"
							);
						}}
					>
						<BsDownload color="#e41e8e" size={12} />
						<p className="text-xs font-roboto text-pink ml-1">Download Chart</p>
					</button>
				</div>
			)}
			<div
				className="flex w-full justify-center ml-4"
				style={{ display: "none" }}
				id={collection?.name + "-leverage-stamp"}
			>
				<p
					className="font-roboto text-pink"
					style={{ fontSize: 8, paddingBottom: 4, marginTop: 0 }}
				>
					Source: https://loans.monkeybaby.business
				</p>
			</div>
		</div>
	);
};

export default CollectionItem;
