import React from "react";
import { useState, useEffect, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { SearchResultsList } from "./SearchResultsList";

export const SearchBar = ({
	searchQuery,
	setSearchQuery,
	clearSearch,
	searchAlgolia,
	setSearchResults,
	searchResults,
}) => {
	const navigate = useNavigate();

	const onSearch = (e) => {
		e.preventDefault();
		if (searchQuery != "") {
			navigate({
				pathname: "/",
				search: "?search=" + searchQuery.toLowerCase(),
			});
		} else {
			navigate({ pathname: "/" });
		}
		setSearchResults([]);
	};

	const handleInputChange = (event) => {
		setSearchQuery(event.target.value);
		triggerSearch(event.target.value);
	};

	// A ref to store the timer
	const searchTimer = useRef(null);

	const triggerSearch = (query) => {
		// Clear the existing timer
		if (searchTimer.current) {
			clearTimeout(searchTimer.current);
		}

		// Set a new timer
		searchTimer.current = setTimeout(() => {
			searchAlgolia(query);
		}, 400); // 0.4 seconds
	};

	useEffect(() => {
		// Cleanup on component unmount
		return () => {
			if (searchTimer.current) {
				clearTimeout(searchTimer.current);
			}
		};
	}, []);

	return (
		<div>
			<form onSubmit={onSearch} className="flex flex-row items-center">
				<div className="py-4 pr-2 pl-0 md:pl-4">
					<div className="relative text-gray-600 dark:text-dark-gray">
						<span className="absolute inset-y-0 left-0 flex items-center pl-4">
							<FaSearch />
						</span>
						<input
							type="text"
							value={searchQuery}
							onChange={handleInputChange}
							className="h-12 pl-10 pr-4 rounded-lg border-2 border-gray-300 dark:border-dark-gray dark:bg-light-black dark:text-white !outline-none "
							placeholder="Search Collections"
						/>
						{searchQuery != "" && (
							<button
								onClick={() => {
									clearSearch();
								}}
								type="button"
								className="absolute inset-y-0 right-0 flex items-center pr-2"
							>
								<IoClose />
							</button>
						)}
					</div>
				</div>

				<button
					type="submit"
					className="h-12 px-4 rounded-xl truncate bg-pink items-center flex text-white font-cubano"
				>
					Search
				</button>
			</form>
			{searchResults.length > 0 && (
				<SearchResultsList
					{...{ searchResults, setSearchResults, setSearchQuery }}
				/>
			)}
		</div>
	);
};

export default SearchBar;
