/** Used to stylize the scatter graphs */
export const SCATTER_DARK_COLORS = {
	WHITE: "#FFFFFF",
	GRAY: "#666667",
	AXIS_LINE_COLOR: "#313237",
	SAFE_LOANS_POINT_COLOR: "#43C621",
	UNSAFE_LOANS_POINT_COLOR: "#F82FA1",
	FORECLOSABLE_LOANS_POINT_COLOR: "#C5C5C5",
};

export const SCATTER_LIGHT_COLORS = {
	BLACK: "#666667",
	AXIS_LINE_COLOR: "#e5e7eb",
	SAFE_LOANS_POINT_COLOR: "rgba(31, 135, 4, 1)",
	UNSAFE_LOANS_POINT_COLOR: "#e41e8e",
	FORECLOSABLE_LOANS_POINT_COLOR: "#808080",
};

export const BARCHART_DARK_COLORS = {
	WHITE: "#FFFFFF",
	GRAY: "#666667",
	AXIS_LINE_COLOR: "#313237",

	LTV_BKG_COLOR: "rgba(255, 57, 93, 0.5)",
	LTV_BORDER_COLOR: "rgb(255, 57, 93)",

	FORECLOSABLE_BKG_COLOR: "rgba(227, 227, 227, 0.5)",
	FORECLOSABLE_BORDER_COLOR: "rgb(227, 227, 227)",
};

export const BARCHART_LIGHT_COLORS = {
	BLACK: "#666667",
	AXIS_LINE_COLOR: "#e5e7eb",

	LTV_BKG_COLOR: "rgba(255, 99, 132, 0.5)",
	LTV_BORDER_COLOR: "rgb(255, 99, 132)",

	FORECLOSABLE_BKG_COLOR: "rgba(21, 11, 47, 0.5)",
	FORECLOSABLE_BORDER_COLOR: "rgb(21, 11, 47)",
};

/** Used to style the React-Select / Dropdown Buttons */
/** Dark-mode dropdown button styles */
export const DARK_SELECT_STYLES = {
	control: (baseStyles) => ({
		...baseStyles,
		minWidth: "200px",
		maxWidth: "175px",
		border: "1px solid #818181",
		backgroundColor: "#1E1F25",
		boxShadow: "none",
		color: "#FFFFFF",
		"&:hover": {
			border: "1px solid #FFF",
		},
	}),

	menu: (base) => ({
		...base,
		border: "1px solid #313237",
	}),

	menuList: (base) => ({
		...base,
		border: "1px solid #313237",
		backgroundColor: "#1E1F25",
	}),

	option: (provided, state) => {
		let backgroundColor = "#1E1F25";
		if (state.isSelected) {
			backgroundColor = "#062847";
		}
		return {
			...provided,
			backgroundColor,
			color: "#fff",

			"&:hover": {
				backgroundColor: "#0B0B0B",
			},
		};
	},

	singleValue: (provided) => ({
		...provided,
		color: "#fff",
	}),
};

/** Light-mode dropdown button styles */
export const LIGHT_SELECT_STYLES = {
	control: (baseStyles) => ({
		...baseStyles,
		minWidth: "200px",
		maxWidth: "175px",
		border: "1px solid #D2D5DA",
		backgroundColor: "#fff",
		boxShadow: "none",
		color: "#000",
		"&:hover": {
			border: "1px solid #D2D5DA",
		},
	}),

	option: (provided, state) => {
		let backgroundColor = "#fff";
		if (state.isSelected) {
			backgroundColor = "#B8DDFF";
		}
		return {
			...provided,
			backgroundColor,
			color: "#000",

			"&:hover": {
				backgroundColor: "#F0F0F0",
			},
		};
	},

	singleValue: (provided) => ({
		...provided,
		color: "#000",
	}),
};
