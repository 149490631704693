import React from "react";
import StatsBox from "./StatsBox";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { BsFillInfoCircleFill } from "react-icons/bs";
import moment from "moment/moment";
import { NavLink } from "react-router-dom";

export const StatsList = ({
	collection = null,
	quickStats,
	overviewPage = false,
}) => {
	return (
		<div className="flex flex-col">
			{overviewPage && (
				<div className="flex flex-row items-center mb-2">
					<NavLink
						to={"/loans/" + collection?.magicEdenSlug}
						className="flex flex-row items-center"
					>
						<img
							src={
								collection?.imageUrl
									? "https://res.cloudinary.com/dsssgubl9/image/fetch/w_200,h_200,c_fill/" +
									  encodeURI(collection?.imageUrl)
									: "https://res.cloudinary.com/dsssgubl9/image/fetch/w_200,h_200,c_fill/" +
									  encodeURI(
											"https://creator-hub-prod.s3.us-east-2.amazonaws.com/unknownabstracts_pfp_1650063070880.png"
									  )
							}
							alt={collection?.name + " collection image"}
							className="border	border-black rounded-full object-contain h-12 w-12 mr-2"
						/>

						<p className="text-lg font-roboto font-bold text-dark-blue dark:text-white">
							{collection?.name
								? collection?.name.length > 35
									? collection?.name?.substring(0, 30) + "..."
									: collection?.name
								: "-"}
						</p>
					</NavLink>
					{/* ) : (
					<>
						<img
							src={
								collection?.imageUrl
									? "https://res.cloudinary.com/dsssgubl9/image/fetch/w_200,h_200,c_fill/" +
									  encodeURI(collection?.imageUrl)
									: "https://res.cloudinary.com/dsssgubl9/image/fetch/w_200,h_200,c_fill/" +
									  encodeURI(
											"https://creator-hub-prod.s3.us-east-2.amazonaws.com/unknownabstracts_pfp_1650063070880.png"
									  )
							}
							alt={collection?.name + " collection image"}
							className="border	border-black rounded-full object-contain h-12 w-12 mr-2"
						/>

						<h1 className="text-lg font-roboto font-bold text-dark-blue">
							{collection?.name
								? collection?.name.length > 35
									? collection?.name?.substring(0, 30) + "..."
									: collection?.name
								: "-"}
						</h1>
					</>
				)} */}
					<a
						data-tooltip-id="my-tooltip"
						data-tooltip-content={
							"Last Updated Loans: " +
							moment(collection?.oldestLoansUpdate * 1000).fromNow()
						}
						style={{
							paddingLeft: "12px",
							paddingRight: "12px",
							paddingTop: "10px",
							paddingBottom: "10px",
						}}
					>
						{" "}
						{/**color="#818181" */}
						<BsFillInfoCircleFill className="text-dark-gray" size={12} />
					</a>
				</div>
			)}

			<table className="w-full text-center text-sm font-roboto text-black">
				<tbody>
					<tr>
						<td>
							<a
								data-tooltip-id="my-tooltip"
								data-tooltip-content={
									"Last Updated Floor: " +
									moment(collection?.lastUpdatedFloor?.seconds * 1000).fromNow()
								}
							>
								<StatsBox
									statsLabel="NFT Floor"
									statsValue={collection?.nftFloorPrice?.toFixed(2)}
									floorPrice={true}
								/>
							</a>
						</td>
						<td>
							<StatsBox
								statsLabel="Total Loans"
								statsValue={quickStats?.total}
							/>
						</td>
						<td>
							<a
								data-tooltip-id="my-tooltip"
								data-tooltip-content={"Total Value Locked"}
							>
								<StatsBox statsLabel="TVL" statsValue={quickStats?.total_vol} />
							</a>
						</td>
					</tr>
					<tr>
						{/* <td>
							<StatsBox
								statsLabel="Loan Floor"
								statsValue={quickStats?.loan_floor}
							/>
						</td> */}
						<td>
							<StatsBox
								statsLabel="Avg Loan"
								statsValue={quickStats?.avg_loan}
							/>
						</td>
						<td>
							<a
								data-tooltip-id="my-tooltip"
								data-tooltip-content={"Loans: Loan Price > NFT Floor Price"}
							>
								<StatsBox
									statsLabel="Underwater"
									statsValue={quickStats?.underwater}
								/>
							</a>
						</td>

						<td>
							<StatsBox
								statsLabel="Avg Underwater"
								statsValue={
									quickStats?.underwater &&
									quickStats?.underwater != 0 &&
									quickStats?.underwater_sum != 0 &&
									(quickStats?.underwater_sum / quickStats?.underwater).toFixed(
										2
									)
								}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<a
								data-tooltip-id="my-tooltip"
								data-tooltip-content={"Total Loans / Total NFT Supply"}
							>
								<StatsBox
									statsLabel="Pct Loaned"
									statsValue={
										collection?.supply &&
										collection?.supply != 0 &&
										quickStats?.total &&
										((quickStats?.total / collection?.supply) * 100).toFixed(
											2
										) + "%"
									}
								/>
							</a>
						</td>

						<td>
							<a
								data-tooltip-id="my-tooltip"
								data-tooltip-content={
									"Expired loans that haven't been repaid or defaulted."
								}
							>
								<StatsBox
									statsLabel="Foreclosable"
									statsValue={quickStats?.forecloseable}
								/>
							</a>
						</td>
						<td>
							<StatsBox
								statsLabel="Avg UW Expiry"
								statsValue={
									quickStats?.underwater &&
									quickStats?.underwater != 0 &&
									quickStats?.underwater_expiry &&
									(
										quickStats?.underwater_expiry / quickStats?.underwater
									).toFixed(2) + " Days"
								}
							/>
						</td>
					</tr>
				</tbody>
			</table>
			<a
				data-tooltip-id="my-tooltip"
				data-tooltip-content={"Loan-to-Value: Avg Loan / NFT Floor"}
			>
				<div
					className="border border-gray dark:border-[#313237]  rounded-md my-1 mx-1 py-1 text-center"
					style={{ width: "345px" }}
				>
					<p className="text-xs font-roboto text-dark-gray dark:text-[#C5C5C5]">
						LTV
					</p>
					<p
						className="text-sm font-roboto font-semibold text-black dark:text-white"
						style={
							quickStats?.ltv <= 80
								? { color: "#428525" }
								: quickStats?.ltv >= 90
								? { color: "red" }
								: { color: "orange" }
						}
					>
						{quickStats?.ltv ? quickStats?.ltv + "%" : "-"}
					</p>
				</div>
			</a>
			<Tooltip
				id="my-tooltip"
				className="dark:!bg-white dark:!text-black font-roboto font-medium"
			/>
		</div>
	);
};

export default StatsList;
