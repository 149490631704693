import { COLLECTION_NAMES } from "../../values/constants";
import { getCollectionReference } from "../firebase.service";
import algoliasearch from "algoliasearch";

/**
 * Used to fetch multiple SOL NFT collections.
 * @param {*} search - search query
 * @param {*} limit - limit of collections to fetch
 * @returns SOL NFT collections list of data.
 */
export const fetchMultipleCollections = (
	search,
	searchList = [],
	startAfter = null
) => {
	return new Promise(async (resolve, reject) => {
		try {
			const limit = 12;
			/** Creates a reference to the MBB holders collection in Firestore */
			let solColRef = getCollectionReference(
				COLLECTION_NAMES.SOLANA_COLLECTIONS
			);

			const colList = [];

			if (search) {
				if (searchList.length == 0) {
					resolve({ colList: [], limitReached: true, startAfter: null });
					return;
				}
				solColRef
					.where("name", "in", searchList)
					.limit(limit)
					.get()
					.then(async (instance) => {
						if (instance.size > 0) {
							let i = 0;
							instance.forEach((collection) => {
								const id = collection.id;
								const holderData = collection.data();
								const oldestLoansUpdate = Math.min(
									holderData?.lastUpdatedLoans?.seconds,
									holderData?.lastUpdatedCitrusLoans?.seconds
								);
								colList.push({ id, oldestLoansUpdate, ...holderData });

								if (i == instance.size - 1) {
									resolve({
										colList,
										limitReached: true, //limit > instance.size,
										startAfter: null,
									});
								}
								i += 1;
							});
						} else {
							resolve({ colList: [], limitReached: true, startAfter: null });
						}
					});
				return;
			} else {
				solColRef = solColRef
					.where("active", "==", true)
					.orderBy("marketCap", "desc");

				if (startAfter) {
					const afterDoc = await getCollectionReference(
						COLLECTION_NAMES.SOLANA_COLLECTIONS
					)
						.doc(startAfter)
						.get();
					solColRef = solColRef.startAfter(afterDoc);
				}

				solColRef
					.limit(limit)
					.get()
					.then(async (instance) => {
						if (instance.size > 0) {
							let i = 0;
							instance.forEach((collection) => {
								const id = collection.id;
								const holderData = collection.data();
								const oldestLoansUpdate = Math.min(
									holderData?.lastUpdatedLoans?.seconds,
									holderData?.lastUpdatedCitrusLoans?.seconds
								);
								colList.push({ id, oldestLoansUpdate, ...holderData });

								if (i == instance.size - 1) {
									resolve({
										colList,
										limitReached: limit > instance.size,
										startAfter: id,
									});
								}
								i += 1;
							});
						} else {
							resolve({ colList: [], limitReached: true, startAfter: null });
						}
					});
			}
		} catch (error) {
			console.log("error", error);
			reject(error);
		}
	});
};

/**
 * Used to fetch a SOL NFT collection.
 * @param {*} helloMoonCollectionId - collection's HM id
 * @returns SOL NFT collection data
 */
export const fetchCollection = (magicEdenSlug) => {
	return new Promise((resolve, reject) => {
		try {
			/** Creates a reference to the MBB holders collection in Firestore */
			const solColRef = getCollectionReference(
				COLLECTION_NAMES.SOLANA_COLLECTIONS
			);

			solColRef
				.where("magicEdenSlug", "==", magicEdenSlug)
				.limit(1)
				.get()
				.then(async (instance) => {
					if (instance.size > 0) {
						instance.forEach((collection) => {
							const id = collection.id;
							const holderData = collection.data();

							const oldestLoansUpdate = Math.min(
								holderData?.lastUpdatedLoans?.seconds,
								holderData?.lastUpdatedCitrusLoans?.seconds
							);

							resolve({
								col: { id, oldestLoansUpdate, ...holderData },
								invalid: false,
							});
						});
					} else {
						resolve({ col: [], invalid: true });
					}
				});
		} catch (error) {
			console.log("fetchCollection | error", error);
			reject(error);
		}
	});
};

/**
 * Used to fetch top SOL NFT collections
 * for global index display
 * @param {*} limit How many NFT collections to fetch
 * @returns list of top SOL NFT collections
 */
export const fetchTopSolCollections = (limit) => {
	return new Promise((resolve, reject) => {
		try {
			/** Creates a reference to the MBB holders collection in Firestore */
			const colRef = getCollectionReference(
				COLLECTION_NAMES.SOLANA_COLLECTIONS
			);

			const solCollections = [];

			colRef
				.where("active", "==", true)
				.orderBy("marketCap", "desc")
				.limit(limit)
				.get()
				.then(async (instance) => {
					if (instance.size > 0) {
						let i = 0;
						instance.forEach((solCol) => {
							const id = solCol.id;
							const data = solCol.data();
							solCollections.push({ id, ...data });
							if (i == instance.size - 1) {
								resolve(solCollections);
							}
							i += 1;
						});
					} else {
						resolve([]);
					}
				});
		} catch (error) {
			console.log("fetchTopSolCollections | error", error);
			reject(error);
		}
	});
};

/**
 * Used to fetch multiple SOL NFT collections.
 * @param {*} keyword - search keyword
 * @param {*} limit - limit of collections to fetch
 * @returns SOL NFT collections list of data.
 */
export const searchCollectionsOnAlgolia = (keyword, limit) => {
	return new Promise((resolve, reject) => {
		try {
			const algoliaClient = algoliasearch(
				"84KAITT1O4",
				"4fbf66cb434ec0f11ccf1dbbe2b1fd6d"
			);
			const searchListHMIds = [];

			const collectionIndex = algoliaClient.initIndex("solanaCollections");

			collectionIndex
				.search(keyword, {
					hitsPerPage: limit,
					numericFilters: ["active = 1"],
				})
				.then(({ hits }) => {
					for (let index = 0; index < hits.length; index++) {
						if (hits[index]?.name) {
							searchListHMIds.push(hits[index]?.name);
						}
					}
					resolve(searchListHMIds);
				});
		} catch (error) {
			reject(error);
		}
	});
};
