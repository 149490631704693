import { COLLECTION_NAMES, LOAN_PLATFORMS } from "../../values/constants";
import { LOAN_PLATFORM_LOGOS, RARITY_LOGOS } from "../../values/images";
import { getCollectionReference } from "../firebase.service";
import { adjustScatterTheme } from "./chart-theme-service";
import { Tooltip } from "chart.js";

/**
 * Used to fetch loans for a specific SOL NFT collections.
 * @param {*} collection - selected NFT collection
 * @param {*} loanPlatform - selected loan platform(s)
 * @param {*} chartOptions - scatter graph chart options
 * @returns SOL NFT collections list of data
 */
export const fetchLoansByCollection = async (
	collection,
	loanPlatform,
	chartOptions,
	theme = "light"
) => {
	return new Promise((resolve, reject) => {
		try {
			/** Creates a reference to the MBB holders collection in Firestore */
			let loansColRef = getCollectionReference(
				COLLECTION_NAMES.GROUPED_NFT_LOANS
			);

			const safeLoans = [];
			const unsafeLoans = [];
			const forecloseableLoans = [];
			const stats = [];

			loansColRef = loansColRef
				.where("active", "==", true)
				.where("name", "==", collection?.name);

			if (loanPlatform != "all") {
				loansColRef = loansColRef.where("loanPlatform", "==", loanPlatform);
			}

			loansColRef.get().then(async (instance) => {
				stats.total = 0;
				stats.total_vol = 0;
				stats.underwater = 0;
				stats.avg_loan = 0;
				stats.loan_floor = 0;
				stats.underwater_sum = 0;
				stats.underwater_expiry = 0;
				stats.forecloseable = 0;
				stats.loan_ceil = 0;
				stats.oldest_loan = 9999;
				stats.ltv = 0;

				if (instance.size > 0) {
					const currentDate = new Date();

					let i = 0;
					instance.forEach(async (loanDoc) => {
						// const id = loanDoc.id;
						const colData = loanDoc.data();
						const loansList = colData?.loanList;

						stats.total += loansList.length;
						for (let index = 0; index < loansList.length; index++) {
							const loansData = loansList[index];

							// Only use loans that haven't been
							// repayed AND haven't defaulted
							if (
								loansData?.amountOffered &&
								!loansData.amountRepayed &&
								!loansData.defaultBlocktime
							) {
								stats.total_vol += loansData.amountOffered;

								// Convert days to unix timestamp
								let sharkyLoanDuration = 14;
								if (collection?.sharkyLoanDuration) {
									sharkyLoanDuration = Number(collection?.sharkyLoanDuration);
								}

								let expiryDate = new Date(
									(Number(loansData.takenBlocktime) +
										sharkyLoanDuration * 86400) *
										1000
								);
								// If loan has available loanDurationSeconds, use that instead
								if (loansData?.loanDurationSeconds) {
									expiryDate = new Date(
										(Number(loansData.takenBlocktime) +
											Number(loansData?.loanDurationSeconds)) *
											1000
									);
								}

								// Calculate the difference in milliseconds, 86,400,000 milliseconds in a day
								// Convert milliseconds to days
								let diffDays = (expiryDate - currentDate) / 86400000;
								if (diffDays < -2) {
									// set the max to -2
									diffDays = -2;
								}

								if (diffDays < stats.oldest_loan) {
									stats.oldest_loan = diffDays;
								}

								if (
									(!stats.loan_floor ||
										loansData.amountOffered <= stats.loan_floor) &&
									loansData?.amountOffered
								) {
									stats.loan_floor = loansData?.amountOffered?.toFixed(2);
								}

								if (
									loansData.amountOffered >= stats.loan_ceil &&
									loansData?.amountOffered
								) {
									stats.loan_ceil = loansData?.amountOffered?.toFixed(2);
								}

								// Determines the NFT item's rarity rank
								// and which platform gave the rarity (HowRare, Moonrank, etc.)
								// Priority order: HowRare -> MoonRank -> Hyperspace
								// for which rarity rank to use (based on available data).
								let rarityRank = -1;
								let rarityType = "";

								if (loansData?.howrareRank) {
									rarityRank = loansData?.howrareRank;
									rarityType = "howrare";
								} else if (loansData?.moonrank) {
									rarityRank = loansData?.moonrank;
									rarityType = "moonrank";
								} else if (loansData?.rankEst) {
									rarityRank = loansData?.rankEst;
									rarityType = "hyperspace";
								} else if (loansData?.rarityEst) {
									rarityRank = loansData?.rarityEst;
									rarityType = "hyperspace";
								}

								// Loan is forecloseable (the lender can close the loan)
								// Forecloseable: any loans that have expired
								// and haven’t been paid back or defaulted yet.
								if (diffDays < 0) {
									stats.forecloseable += 1;
									forecloseableLoans.push({
										x: diffDays,
										y: loansData.amountOffered,
										type: colData?.loanPlatform
											? colData?.loanPlatform.charAt(0).toUpperCase() +
											  colData?.loanPlatform.slice(1)
											: null,
										name: loansData?.name ? loansData?.name : null,
										imageUrl: loansData?.imageUrl ? loansData?.imageUrl : null,
										rarity: rarityRank,
										rarityType,
									});
								}
								// Loan is underwater ONLY
								// if the loan borrowed amount is greater
								// than the current floor price
								// X if the loan has defaulted already
								else if (
									loansData.amountOffered >= Number(collection?.nftFloorPrice)
								) {
									stats.underwater += 1;
									stats.underwater_sum += loansData.amountOffered;
									stats.underwater_expiry += diffDays;
									unsafeLoans.push({
										x: diffDays,
										y: loansData.amountOffered,
										type: colData?.loanPlatform
											? colData?.loanPlatform.charAt(0).toUpperCase() +
											  colData?.loanPlatform.slice(1)
											: null,
										name: loansData?.name ? loansData?.name : null,
										imageUrl: loansData?.imageUrl ? loansData?.imageUrl : null,
										rarity: rarityRank,
										rarityType,
									});
								} else {
									safeLoans.push({
										x: diffDays,
										y: loansData.amountOffered,
										type: colData?.loanPlatform
											? colData?.loanPlatform.charAt(0).toUpperCase() +
											  colData?.loanPlatform.slice(1)
											: null,
										name: loansData?.name ? loansData?.name : null,
										imageUrl: loansData?.imageUrl ? loansData?.imageUrl : null,
										rarity: rarityRank,
										rarityType,
									});
								}
							}
						}

						if (i == instance.size - 1) {
							// Ensures deep clone of chartOptions
							let temp = JSON.parse(JSON.stringify(chartOptions));
							temp.plugins.title.text = collection?.name + " Loans";

							temp.plugins.annotation.annotations = {
								display: true,
								line1: {
									type: "line",
									yMin: collection?.nftFloorPrice,
									yMax: collection?.nftFloorPrice,
									borderColor: "#DB7130",
									borderWidth: 2,
								},
							};

							temp.plugins.tooltip = {
								events: [
									"mousemove",
									"mouseleave",
									"click",
									"touchstart",
									"touchmove",
									"touchend",
								],
								enabled: false,
								// Use custom positioning on tooltip so doesn't overflow off the page
								// on edge points on the graph
								position: "myCustomPositioner",

								// Use an external tooltip to allow for scrolling
								external: externalTooltipHandler,
								callbacks: {
									title: (xDatapoint) => {
										return xDatapoint[0]?.dataset?.label;
									},

									label: (xDatapoint) => {
										if (xDatapoint?.raw?.name) {
											return [
												xDatapoint?.raw?.name ? xDatapoint?.raw?.name : "",
												xDatapoint?.parsed?.y?.toFixed(2) +
													" SOL, " +
													(xDatapoint?.raw?.type == "Frakt"
														? "Perp Loan "
														: xDatapoint?.parsed?.x?.toFixed(2) +
														  " Days Left ") +
													(xDatapoint?.raw?.type
														? "(" +
														  (xDatapoint?.raw?.type != "Frakt"
																? xDatapoint?.raw?.type
																: "Banx") +
														  ")"
														: ""),
												"",
											];
										} else {
											return [
												xDatapoint?.parsed?.y?.toFixed(2) +
													" SOL, " +
													(xDatapoint?.raw?.type == "Frakt"
														? "Perp Loan "
														: xDatapoint?.parsed?.x?.toFixed(2) +
														  " Days Left ") +
													(xDatapoint?.raw?.type
														? "(" +
														  (xDatapoint?.raw?.type != "Frakt"
																? xDatapoint?.raw?.type
																: "Banx") +
														  ")"
														: ""),
												"",
											];
										}
									},

									// NFT item's image to display
									labelPointStyle: (xDatapoint) => {
										const image = new Image(35, 35);
										if (xDatapoint?.raw?.imageUrl) {
											image.src =
												"https://ik.imagekit.io/47y3g0iov/tr:n-nftloans/" +
												encodeURI(xDatapoint?.raw?.imageUrl);
										} else {
											image.src =
												"https://creator-hub-prod.s3.us-east-2.amazonaws.com/unknownabstracts_pfp_1650063070880.png";
										}
										return {
											pointStyle: image,
										};
									},
								},
							};

							const newData = {
								datasets: [
									{
										label: "Safe Loans",
										data: safeLoans,
										//pointBackgroundColor: "rgba(31, 135, 4, 1)",
									},
									{
										label: "Underwater Loans",
										data: unsafeLoans,
										pointBackgroundColor: "#e41e8e",
									},
									{
										label: "Foreclosable Loans",
										data: forecloseableLoans,
										pointBackgroundColor: "#808080",
									},
								],
							};
							if (!isNaN(collection?.nftFloorPrice)) {
								temp.scales.y.suggestedMin =
									Number(collection?.nftFloorPrice) * 0.5;
							}

							stats.total_vol = stats?.total_vol?.toFixed(2);
							if (stats.total > 0) {
								stats.avg_loan = (stats.total_vol / stats.total)?.toFixed(2);
							}

							if (stats.avg_loan > 0 && collection?.nftFloorPrice > 0) {
								stats.ltv = Number(
									(
										(Number(stats?.avg_loan) /
											Number(collection?.nftFloorPrice)) *
										100
									).toFixed(2)
								);
							}
							const { newGraphData, newChartOptions } =
								await adjustScatterTheme(newData, temp, theme);
							resolve({
								newChartOptions: newChartOptions,
								newQuickStats: stats,
								newGraphData: newGraphData,
							});
						}

						i += 1;
					});
				} else {
					// No loans data found for collection
					const newData = {
						datasets: [
							{
								label: "Safe Loans",
								data: [],
								pointBackgroundColor: "rgba(31, 135, 4, 1)",
							},
							{
								label: "Underwater Loans",
								data: [],
								pointBackgroundColor: "#e41e8e",
							},
						],
					};

					resolve({
						newChartOptions: null,
						newQuickStats: [],
						newGraphData: newData,
					});
				}
			});
		} catch (error) {
			console.log("fetchLoansByCollection | error", error);
			reject(error);
		}
	});
};

/**
 * Custom positioner for tooltip
 * Determine the tooltip's position so it doesn't overflow off the page
 * @function Tooltip.positioners.myCustomPositioner
 * @param elements {Chart.Element[]} the tooltip elements
 * @param eventPosition {Point} the position of the event in canvas coordinates
 * @returns {TooltipPosition} the tooltip position
 */
Tooltip.positioners.myCustomPositioner = function (elements, position) {
	if (!elements.length) {
		return false;
	}

	// If the loans point is on the far right side of the graph,
	// Add offset to ensure the right edge of the tooltip
	// is at least underneath the dot
	var offset = 0;
	if (position.x > 280) {
		offset = position.x - 280;
	}

	return {
		x: 0 + offset,
		y: position.y - 1,
	};
};

const getOrCreateTooltip = (chart, tooltip) => {
	let tooltipEl = chart.canvas.parentNode.querySelector("div");

	if (!tooltipEl) {
		tooltipEl = document.createElement("div");
		//tooltipEl.style.background = "rgba(0, 0, 0, 0.85)";
		//tooltipEl.style.borderRadius = "3px";
		tooltipEl.style.color = "white";
		tooltipEl.style.fontFamily = [
			"Roboto",
			"Arial",
			"Helvetica Neue",
			"sans-serif",
		];
		tooltipEl.style.opacity = 1;
		tooltipEl.style.pointerEvents = "none";
		tooltipEl.style.position = "absolute";
		tooltipEl.style.transform = "translate(-50%, 0)";
		tooltipEl.style.transition = "all .1s ease";
		tooltipEl.style.zIndex = 10000;

		const table = document.createElement("table");

		// Using outerDiv and tableDiv to allow scrolling
		const outerDiv = document.createElement("div");
		outerDiv.style.position = "flex";
		// If show tooltip
		outerDiv.style.pointerEvents = "auto";
		outerDiv.style.background = "rgba(0, 0, 0, 0.85)";
		outerDiv.style.borderRadius = "3px";

		const tableDiv = document.createElement("div");
		tableDiv.style.position = "absolute";
		tableDiv.style.maxHeight = "350px";
		tableDiv.style.overflowY = "scroll";
		// If show tooltip
		tableDiv.style.pointerEvents = "auto";
		tableDiv.style.background = "rgba(0, 0, 0, 0.85)";
		tableDiv.style.borderRadius = "3px";
		tableDiv.appendChild(table);

		outerDiv.appendChild(tableDiv);

		tooltipEl.appendChild(outerDiv);
		chart.canvas.parentNode.appendChild(tooltipEl);
	}

	return tooltipEl;
};

const externalTooltipHandler = (context) => {
	// Tooltip Element
	const { chart, tooltip } = context;
	const tooltipEl = getOrCreateTooltip(chart, tooltip);

	// Hides tooltip
	if (tooltip.opacity === 0) {
		tooltipEl.style.zIndex = -1;
		tooltipEl.style.opacity = 0;
		return;
	}

	// Set Tooltip body with loans
	if (tooltip.body) {
		const titleLines = tooltip.title || [];
		const bodyLines = tooltip.body.map((b) => b.lines);
		const imageLines = tooltip.labelPointStyles.map((img) => img.pointStyle);

		const tableHead = document.createElement("thead");
		tableHead.style.height = "40px";
		tableHead.style.alignItems = "flex-start";

		titleLines.forEach((title) => {
			const tr = document.createElement("tr");
			tr.style.borderWidth = 0;

			const th = document.createElement("th");
			th.style.borderWidth = 0;

			const text = document.createTextNode(title);

			th.appendChild(text);
			tr.appendChild(th);
			tableHead.appendChild(tr);
		});

		const tableBody = document.createElement("tbody");

		// Adds each NFT loan that is selected to the tooltip
		bodyLines.forEach((body, i) => {
			const colors = tooltip.labelColors[i];

			const tr = document.createElement("tr");
			tr.style.backgroundColor = "inherit";
			tr.style.borderWidth = 0;
			tr.style.minWidth = "280px";

			const td = document.createElement("td");
			td.style.borderWidth = 0;
			td.style.fontSize = "14px";

			// Used to create row of NFT item image and NFT loan text
			const div = document.createElement("div");
			div.style.display = "flex";
			div.style.flexDirection = "row";
			div.style.justifyContent = "start";
			div.style.textAlign = "left";
			div.style.minWidth = "280px";
			div.style.marginLeft = "8px";
			div.style.marginRight = "8px";
			div.style.marginBottom = "12px";

			// Adds NFT item images to tooltip
			imageLines[i].style.marginRight = "8px";
			imageLines[i].style.height = "40px";
			imageLines[i].style.width = "40px";
			imageLines[i].style.objectFit = "cover";
			imageLines[i].style.borderWidth = "2px";
			imageLines[i].style.background = colors.backgroundColor;
			imageLines[i].style.borderColor = colors.borderColor;

			td.appendChild(div);
			div.appendChild(imageLines[i]);

			// Adds correct rarity logo to NFT loan
			const rarityLogo = new Image(14, 14);
			rarityLogo.src = RARITY_LOGOS.MOONRANK;
			if (tooltip?.dataPoints[i]?.raw?.rarityType) {
				if (tooltip?.dataPoints[i]?.raw?.rarityType == "howrare") {
					rarityLogo.src = RARITY_LOGOS.HOWRARE_IS;
				} else if (tooltip?.dataPoints[i]?.raw?.rarityType == "moonrank") {
					rarityLogo.src = RARITY_LOGOS.MOONRANK;
				} else if (tooltip?.dataPoints[i]?.raw?.rarityType == "hyperspace") {
					rarityLogo.src = RARITY_LOGOS.HYPERSPACE;
				}
			}
			rarityLogo.style.height = "14px";
			rarityLogo.style.width = "14px";
			rarityLogo.style.marginLeft = "4px";
			rarityLogo.style.paddingBottom = "2px";
			rarityLogo.style.objectFit = "contain";

			const textDiv = document.createElement("div");
			textDiv.style.display = "flex";
			textDiv.style.flexDirection = "column";

			body.forEach((lineOfText, index) => {
				const text = document.createTextNode(lineOfText);
				// If on the first line of text for the NFT loan (the name and rarity rank)
				// Correctly stylize / add the name, rarity logo and rarity rank
				if (index === 0) {
					const innerDiv = document.createElement("div");
					innerDiv.style.display = "flex";
					innerDiv.style.flexDirection = "row";
					innerDiv.style.alignItems = "center";
					innerDiv.appendChild(text);

					// If the NFT loan has a rarity score, adds it to the tooltip
					if (tooltip?.dataPoints[i]?.raw?.rarityType) {
						innerDiv.appendChild(rarityLogo);
						const rarityText = document.createTextNode(
							tooltip?.dataPoints[i]?.raw?.rarity?.toString()
						);
						innerDiv.appendChild(rarityText);
					}
					textDiv.appendChild(innerDiv);
				} else {
					// Second line of text for the NFT loan
					textDiv.appendChild(text);
				}
			});
			div.appendChild(textDiv);
			tr.appendChild(td);
			tableBody.appendChild(tr);
		});

		const tableRoot = tooltipEl.querySelector("table");

		// Remove old children
		while (tableRoot.firstChild) {
			tableRoot.firstChild.remove();
		}

		// Add new children
		tableRoot.appendChild(tableHead);
		tableRoot.appendChild(tableBody);
	}

	const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

	// Display, position, and set styles for font
	tooltipEl.style.opacity = 1;
	tooltipEl.style.left = positionX + tooltip.caretX + "px";
	tooltipEl.style.top = positionY + tooltip.caretY + "px";
	tooltipEl.style.font = tooltip.options.bodyFont.string;
	tooltipEl.style.padding =
		tooltip.options.padding + "px " + tooltip.options.padding + "px";

	tooltipEl.style.zIndex = 1000;
};
