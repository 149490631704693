import {
	COLLECTION_NAMES,
	INITIAL_LOAN_HISTORY_GRAPH_OPTIONS,
	INITIAL_GLOBAL_LOAN_HISTORY_GRAPH_OPTIONS,
} from "../../values/constants";
import { getCollectionReference } from "../firebase.service";
import { adjustBarTheme } from "./chart-theme-service";
import { fetchTopSolCollections } from "./collection-service";

/**
 * Used to fetch historical loans stats
 * @param {*} collection - selected NFT collection
 * @returns loans history by collection
 */
export const fetchLoansHistoryByCollection = async (
	collection,
	chartOptions,
	quickStats = null,
	theme = "light"
) => {
	return new Promise((resolve, reject) => {
		try {
			/** Creates a reference to the loans history collection in Firestore */
			let loansHistoryRef = getCollectionReference(
				COLLECTION_NAMES.LOANS_HISTORY
			);

			const ltvList = [];
			const tvlList = [];
			const fpList = [];
			const forecloseableList = [];

			loansHistoryRef = loansHistoryRef
				.where("active", "==", true)
				.where("name", "==", collection?.name)
				.orderBy("createdAt", "asc");

			loansHistoryRef.get().then(async (instance) => {
				if (instance.size > 0) {
					let i = 0;
					let lowestFp = 99999;
					let highestFp = -1;
					let lowestVol = 999999999;
					let highestVol = -1;
					instance.forEach(async (loanDoc) => {
						const loansData = loanDoc.data();

						ltvList.push({
							x: loansData?.priceDate,
							y: loansData?.ltv,
						});

						forecloseableList.push({
							x: loansData?.priceDate,
							y: loansData?.totalForeclosable,
						});

						tvlList.push({
							x: loansData?.priceDate,
							y: loansData?.tvl,
						});

						fpList.push({
							x: loansData?.priceDate,
							y: loansData?.nftFloorPrice,
						});

						if (loansData?.nftFloorPrice < lowestFp) {
							lowestFp = loansData?.nftFloorPrice;
						}
						if (loansData?.nftFloorPrice > highestFp) {
							highestFp = loansData?.nftFloorPrice;
						}

						if (loansData?.tvl < lowestVol) {
							lowestVol = loansData?.tvl;
						}
						if (loansData?.tvl > highestVol) {
							highestVol = loansData?.tvl;
						}

						if (i == instance.size - 1) {
							// Will add a data point for the live data based on the quickStats
							if (quickStats) {
								ltvList.push({
									x: "Live",
									y: quickStats?.ltv,
								});
								tvlList.push({
									x: "Live",
									y: quickStats?.total_vol,
								});
								fpList.push({
									x: "Live",
									y: Number(collection?.nftFloorPrice?.toFixed(2)),
								});
								forecloseableList.push({
									x: "Live",
									y: quickStats?.forecloseable,
								});

								if (Number(collection?.nftFloorPrice?.toFixed(2)) < lowestFp) {
									lowestFp = Number(collection?.nftFloorPrice?.toFixed(2));
								}
								if (Number(collection?.nftFloorPrice?.toFixed(2)) > highestFp) {
									highestFp = Number(collection?.nftFloorPrice?.toFixed(2));
								}

								if (Number(collection?.total_vol?.toFixed(2)) < lowestVol) {
									lowestVol = Number(collection?.total_vol?.toFixed(2));
								}
								if (Number(collection?.total_vol?.toFixed(2)) > highestVol) {
									highestVol = Number(collection?.total_vol?.toFixed(2));
								}
							}

							// Ensures deep clone of chartOptions
							let newChartOptions = JSON.parse(JSON.stringify(chartOptions));
							newChartOptions.plugins.title.text =
								collection?.name + " LTV vs TVL vs Floor Price";

							// FP
							newChartOptions.scales.y = {
								type: "linear",
								display: true,
								position: "left",
								suggestedMin: lowestFp * 0.98,
								suggestedMax: highestFp * 1.02,
								ticks: {
									color: "rgba(133, 53, 235)",
								},
								grid: {
									display: true,
									color: "#e5e7eb",
								},
							};

							newChartOptions.scales.y1.grid.display = false;

							// LTV
							newChartOptions.scales.y1.ticks = {
								color: "rgba(255, 99, 132)",
								position: "left",
								display: true,
								callback(value, index) {
									return value + "%";
								},
							};

							// TVL
							newChartOptions.scales.y2 = {
								suggestedMin: lowestVol * 0.85,
								suggestedMax: highestVol * 1.15,
								position: "right",
								ticks: {
									color: "rgba(53, 162, 235)",
									display: true,
									callback(value) {
										if (value < 1000) return value;
										else if (value < 10000)
											return (value / 1000)?.toFixed(1) + "k";
										else return (value / 1000)?.toFixed(0) + "k";
									},
								},
								grid: {
									display: false,
								},
								border: {
									display: false,
								},
								beginAtZero: false,
							};

							const data = {
								datasets: [
									{
										type: "line",
										label: "LTV",
										data: ltvList,
										borderColor: "rgb(255, 99, 132)",
										backgroundColor: "rgba(255, 99, 132, 0.5)",
										yAxisID: "y1",
									},
									{
										type: "line",
										label: "NFT Floor",
										data: fpList,
										borderColor: "rgba(155, 55, 255)", //"#7c08ff",
										backgroundColor: "rgba(155, 55, 255, 0.5)", //"rgba(133, 53, 235, 0.5)",
										yAxisID: "y",
									},

									{
										type: "line",
										label: "Foreclosable",
										data: forecloseableList,
										borderColor: "rgb(21, 11, 47)",
										backgroundColor: "rgba(21, 11, 47, 0.5)",
										yAxisID: "y3",
									},

									{
										type: "bar",
										label: "Total Volume",
										data: tvlList,
										borderColor: "rgb(53, 162, 235)",
										backgroundColor: "rgba(53, 162, 235, 0.5)",
										yAxisID: "y2",
									},
								],
							};

							const { newLoanHistoryData, newLoansHistoryChartOptions } =
								await adjustBarTheme(data, newChartOptions, theme);

							resolve({
								newHistoryData: newLoanHistoryData,
								newHistoryOptions: newLoansHistoryChartOptions,
								ltvListHistory: ltvList,
								tvlListHistory: tvlList,
								fpListHistory: fpList,
								foreclosableListHistory: forecloseableList,
							});
						}

						i += 1;
					});
				} else {
					let newChartOptions = JSON.parse(JSON.stringify(chartOptions));
					newChartOptions.plugins.title.text =
						collection?.name + " LTV vs TVL vs Floor Price";

					// No loans data found for collection
					resolve({
						newHistoryData: null,
						newHistoryOptions: newChartOptions,
						ltvListHistory: null,
						tvlListHistory: null,
						fpListHistory: null,
						foreclosableListHistory: null,
					});
				}
			});
		} catch (error) {
			console.log("fetchLoansHistoryByCollection | error", error);
			reject(error);
		}
	});
};

/**
 * Fetches top 15 SOL collections, get the loans history
 * to create the global loans history index
 * @param {*} limit - number of top SOL NFT collections to fetch
 * @returns SOL NFT collections list of data
 */
export const fetchGlobalLoansHistory = async (theme = "light", limit = 15) => {
	return new Promise(async (resolve, reject) => {
		try {
			/** Creates a reference to the MBB holders collection in Firestore */
			let solCollections = await fetchTopSolCollections(limit);

			let tvlList = [];
			let ltvList = [];
			let fpList = [];
			let forecloseableList = [];

			// Fetches global market cap used for weighing collection data
			let globalMcap = 0;
			for (let i = 0; i < solCollections.length; i++) {
				if (solCollections[i].nftFloorPrice && solCollections[i].supply) {
					globalMcap +=
						Number(solCollections[i].nftFloorPrice) *
						Number(solCollections[i].supply);
				}
			}

			// Loops through all 15 NFT collections and
			// adds their TVL, LTV, FP, forecl, to global data
			for (let i = 0; i < solCollections.length; i++) {
				const {
					ltvListHistory,
					fpListHistory,
					tvlListHistory,
					foreclosableListHistory,
				} = await fetchLoansHistoryByCollection(
					solCollections[i],
					INITIAL_LOAN_HISTORY_GRAPH_OPTIONS
				);

				// Generates the collection's global weight
				// based on the collection's mcap / global mcap
				const mCap =
					Number(solCollections[i].nftFloorPrice) *
					Number(solCollections[i].supply);
				const collectionWeight = mCap / globalMcap;

				// LTV - Weighted Global
				// Add first collection to create global LTV list array
				if (!ltvList || ltvList.length == 0) {
					ltvList = ltvListHistory;
					for (let j = 0; j < ltvList.length; j++) {
						ltvList[j].y = ltvListHistory[j]?.y * collectionWeight;
					}
				} else {
					// Append LTV data to existing global LTV array
					const colLtvLength = ltvListHistory.length;

					for (let j = 0; j < ltvList.length; j++) {
						if (ltvListHistory[colLtvLength - 1 - j]?.y) {
							ltvList[ltvList.length - 1 - j].y +=
								ltvListHistory[colLtvLength - 1 - j]?.y * collectionWeight;
						}
					}
				}

				// NFT Floor Price - Weighted Global
				// Add first collection to create global FP list array
				// Also weight the data correctly
				if (fpList.length == 0) {
					fpList = fpListHistory;
					for (let j = 0; j < fpList.length; j++) {
						fpList[j].y = fpListHistory[j].y * collectionWeight;
					}
				} else {
					// Append FP data to existing global LTV array
					// and also have the data weighted based on col weight
					const colFloorPriceLength = fpListHistory.length;

					for (let j = 0; j < fpList.length; j++) {
						if (fpListHistory[colFloorPriceLength - 1 - j]?.y) {
							fpList[fpList.length - 1 - j].y +=
								fpListHistory[colFloorPriceLength - 1 - j]?.y *
								collectionWeight;
						}
					}
				}

				// Forecloseables - Global
				// Add up all the total volume across top collections
				if (forecloseableList.length == 0) {
					forecloseableList = foreclosableListHistory;
				} else {
					const colForeclosableLength = foreclosableListHistory.length;

					for (let j = 0; j < forecloseableList.length; j++) {
						if (foreclosableListHistory[colForeclosableLength - 1 - j]?.y) {
							forecloseableList[forecloseableList.length - 1 - j].y +=
								foreclosableListHistory[colForeclosableLength - 1 - j]?.y;
						}
					}
				}

				// TVL - Global
				// Add up all the total volume across top collections
				if (tvlList.length == 0) {
					tvlList = tvlListHistory;
				} else {
					const colTvlLength = tvlListHistory.length;

					for (let j = 0; j < tvlList.length; j++) {
						if (tvlListHistory[colTvlLength - 1 - j]?.y) {
							tvlList[tvlList.length - 1 - j].y +=
								tvlListHistory[colTvlLength - 1 - j]?.y;
						}
					}
				}
			}

			const data = {
				datasets: [
					{
						type: "line",
						label: "LTV",
						data: ltvList,
						borderColor: "rgb(255, 99, 132)",
						backgroundColor: "rgba(255, 99, 132, 0.5)",
						yAxisID: "y1",
					},
					{
						type: "line",
						label: "NFT Floor",
						data: fpList,
						borderColor: "rgba(155, 55, 255)",
						backgroundColor: "rgba(155, 55, 255, 0.5)",
						yAxisID: "y",
					},
					{
						type: "line",
						label: "Foreclosable",
						data: forecloseableList,
						borderColor: "rgb(21, 11, 47)",
						backgroundColor: "rgba(21, 11, 47, 0.5)",
						yAxisID: "y3",
					},
					{
						type: "bar",
						label: "Total Volume",
						data: tvlList,
						borderColor: "rgb(53, 162, 235)",
						backgroundColor: "rgba(53, 162, 235, 0.5)",
						yAxisID: "y2",
					},
				],
			};

			// Finds the lowest+highest FP and VOL to generate
			// the chart Y ranges
			let lowestFp = 999999;
			let highestFp = -1;
			let lowestVol = 999999999;
			let highestVol = -1;

			for (let k = 0; k < fpList.length; k++) {
				if (fpList[k].y < lowestFp) {
					lowestFp = fpList[k].y;
				}
				if (fpList[k].y > highestFp) {
					highestFp = fpList[k].y;
				}
			}
			for (let k = 0; k < tvlList.length; k++) {
				if (tvlList[k].y < lowestVol) {
					lowestVol = tvlList[k].y;
				}
				if (tvlList[k].y > highestVol) {
					highestVol = tvlList[k].y;
				}
			}

			let newChartOptions = JSON.parse(
				JSON.stringify(INITIAL_GLOBAL_LOAN_HISTORY_GRAPH_OPTIONS)
			);
			newChartOptions.plugins.title.text = "SOL Global Leverage Index";

			// FP
			newChartOptions.scales.y = {
				type: "linear",
				display: true,
				position: "left",
				suggestedMin: lowestFp * 0.95,
				suggestedMax: highestFp * 1.05,
				ticks: {
					color: "rgba(133, 53, 235)",
				},
				grid: {
					display: true,
					color: "#e5e7eb",
				},
			};

			// LTV
			newChartOptions.scales.y1.ticks = {
				color: "rgba(255, 99, 132)",
				suggestedMin: 50,
				suggestedMax: 100,
				display: true,
				position: "left",
				callback(value) {
					return value + "%";
				},
			};

			// TVL
			newChartOptions.scales.y2 = {
				suggestedMin: lowestVol * 0.85,
				suggestedMax: highestVol * 1.15,
				position: "right",
				ticks: {
					color: "rgba(53, 162, 235)",
					display: true,
					callback(value) {
						if (value < 1000) return value;
						else if (value < 10000) return (value / 1000)?.toFixed(1) + "k";
						else return (value / 1000)?.toFixed(0) + "k";
					},
				},
				beginAtZero: false,
				grid: {
					display: false,
				},
				border: {
					display: false,
				},
			};

			// Shows the latest LTV, TVL and Floor Price
			let newQuickStats = {
				ltv: ltvList.length > 0 ? ltvList[ltvList.length - 1].y : 0,
				tvl: tvlList.length > 0 ? tvlList[tvlList.length - 1].y : 0,
				floorPrice: fpList.length > 0 ? fpList[fpList.length - 1].y : 0,
				forecloseable:
					forecloseableList.length > 0
						? forecloseableList[forecloseableList.length - 1].y
						: 0,
			};

			const { newLoanHistoryData, newLoansHistoryChartOptions } =
				await adjustBarTheme(data, newChartOptions, theme);

			resolve({
				newGlobalHistoryData: newLoanHistoryData,
				newGlobalHistoryOptions: newLoansHistoryChartOptions,
				newGlobalQuickStats: newQuickStats,
			});
		} catch (error) {
			console.log("fetchGlobalLoansHistory | error", error);
			reject(error);
		}
	});
};
