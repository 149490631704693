import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import UniversalHeader from "../src/components/universal-header/UniversalHeader";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";

const GA_TRACKING_ID = "G-JBCNG5LZBK";
ReactGA.initialize(GA_TRACKING_ID);

function App() {
	const [theme, setTheme] = useState("");

	useEffect(() => {
		if (theme == "") {
			if (localStorage.getItem("theme") != null) {
				setTheme(localStorage.getItem("theme"));
				if (localStorage.getItem("theme") == "dark") {
					document.body.style.backgroundColor = "#1E1F25";
				} else {
					document.body.style.backgroundColor = "#FFFFFF";
				}
			} else {
				setTheme("light");
				localStorage.setItem("theme", "light");
				document.body.style.backgroundColor = "#FFFFFF";
			}
		}
	}, []);

	useEffect(() => {
		if (theme != "") {
			if (theme == "dark") {
				document.body.style.backgroundColor = "#1E1F25";
			} else {
				document.body.style.backgroundColor = "#FFFFFF";
			}
		}
	}, [theme]);

	return (
		<div className={`${theme} bg-black`}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Monkey Baby Business - NFT Loans Analytics</title>
				<meta
					name="description"
					data-react-helmet="true"
					content="Monkey Baby Business is the leading NFT loans analytics platform on Solana. Track the latest Solana NFT loans analytics today."
				/>
			</Helmet>
			<Router>
				<div className="bg-white dark:bg-light-black">
					<UniversalHeader {...{ theme, setTheme }} />
					<AppRoutes {...{ theme, setTheme }} />
				</div>
			</Router>
		</div>
	);
}

export default App;
