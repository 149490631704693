import {
	SCATTER_DARK_COLORS,
	SCATTER_LIGHT_COLORS,
	BARCHART_DARK_COLORS,
	BARCHART_LIGHT_COLORS,
} from "../../values/theme";

var cloneDeep = require("lodash.clonedeep");

/**
 * Used to adjust the scatter chart's theme to be light or dark
 * @param {*} graphData - existing graph data
 * @param {*} chartOptions - existing chart options
 * @param {*} theme - scatter graph theme to set (light or dark)
 * @returns updated graph data and chart options
 */
export const adjustScatterTheme = async (
	graphData,
	chartOptions,
	theme = "light"
) => {
	return new Promise((resolve) => {
		try {
			if (
				localStorage.getItem("theme") != null &&
				localStorage.getItem("theme") != theme
			) {
				theme = localStorage.getItem("theme");
			}

			if (theme == "dark") {
				let newGraphData = null;
				let newChartOptions = cloneDeep(chartOptions);

				/** Change scatter title, y-axis title, x-axis title */
				newChartOptions.plugins.title.color = SCATTER_DARK_COLORS.WHITE;
				newChartOptions.scales.x.title.color = SCATTER_DARK_COLORS.WHITE;
				newChartOptions.scales.y.title.color = SCATTER_DARK_COLORS.WHITE;

				/** Change legend labels text color */
				newChartOptions.plugins.legend.labels.color = SCATTER_DARK_COLORS.WHITE;

				/** Change axis lines */
				newChartOptions.scales.x.grid.color =
					SCATTER_DARK_COLORS.AXIS_LINE_COLOR;
				newChartOptions.scales.y.grid.color =
					SCATTER_DARK_COLORS.AXIS_LINE_COLOR;

				/** Change y-axis and x-axis range values */
				newChartOptions.scales.x.ticks.color = SCATTER_DARK_COLORS.WHITE;
				newChartOptions.scales.y.ticks.color = SCATTER_DARK_COLORS.WHITE;

				if (graphData != null) {
					newGraphData = JSON.parse(JSON.stringify(graphData));
					newGraphData.datasets[0].pointBackgroundColor =
						SCATTER_DARK_COLORS.SAFE_LOANS_POINT_COLOR; // Safe Loans
					newGraphData.datasets[1].pointBackgroundColor =
						SCATTER_DARK_COLORS.UNSAFE_LOANS_POINT_COLOR; // Underwater Loans
					newGraphData.datasets[2].pointBackgroundColor =
						SCATTER_DARK_COLORS.FORECLOSABLE_LOANS_POINT_COLOR; // Foreclosable Loans
				}

				resolve({
					newGraphData,
					newChartOptions,
				});
			} else {
				let newChartOptions = cloneDeep(chartOptions);
				let newGraphData = null;
				newChartOptions.plugins.title.color = SCATTER_LIGHT_COLORS.BLACK;
				newChartOptions.scales.x.title.color = SCATTER_LIGHT_COLORS.BLACK;
				newChartOptions.scales.y.title.color = SCATTER_LIGHT_COLORS.BLACK;

				newChartOptions.plugins.legend.labels.color =
					SCATTER_LIGHT_COLORS.BLACK;
				newChartOptions.scales.x.ticks.color = SCATTER_LIGHT_COLORS.BLACK;
				newChartOptions.scales.y.ticks.color = SCATTER_LIGHT_COLORS.BLACK;

				/** Change axis lines */
				newChartOptions.scales.x.grid.color =
					SCATTER_LIGHT_COLORS.AXIS_LINE_COLOR;
				newChartOptions.scales.y.grid.color =
					SCATTER_LIGHT_COLORS.AXIS_LINE_COLOR;

				if (graphData != null) {
					newGraphData = JSON.parse(JSON.stringify(graphData));
					newGraphData.datasets[0].pointBackgroundColor =
						SCATTER_LIGHT_COLORS.SAFE_LOANS_POINT_COLOR; // Safe Loans
					newGraphData.datasets[1].pointBackgroundColor =
						SCATTER_LIGHT_COLORS.UNSAFE_LOANS_POINT_COLOR; // Underwater Loans
					newGraphData.datasets[2].pointBackgroundColor =
						SCATTER_LIGHT_COLORS.FORECLOSABLE_LOANS_POINT_COLOR; // Foreclosable Loans
				}

				resolve({
					newGraphData,
					newChartOptions,
				});
			}
		} catch (error) {
			console.log("adjustScatterTheme | error", error);
			resolve({ newGraphData: null, newChartOptions: null });
		}
	});
};

/**
 * Used to adjust the bar chart's theme to be light or dark
 * @param {*} loansHistoryData - existing graph data
 * @param {*} loansHistoryChartOptions - existing chart options
 * @param {*} theme - scatter graph theme to set (light or dark)
 * @returns updated graph data and chart options
 */
export const adjustBarTheme = async (
	loansHistoryData,
	loansHistoryChartOptions,
	theme = "light"
) => {
	return new Promise((resolve) => {
		try {
			if (
				localStorage.getItem("theme") != null &&
				localStorage.getItem("theme") != theme
			) {
				theme = localStorage.getItem("theme");
			}

			if (theme == "dark") {
				let newLoanHistoryData = null;
				let newLoansHistoryChartOptions = cloneDeep(loansHistoryChartOptions);

				newLoansHistoryChartOptions.plugins.title.color =
					BARCHART_DARK_COLORS.WHITE;
				newLoansHistoryChartOptions.plugins.legend.labels.color =
					BARCHART_DARK_COLORS.WHITE;

				newLoansHistoryChartOptions.scales.x.ticks.color =
					BARCHART_DARK_COLORS.WHITE;
				newLoansHistoryChartOptions.scales.y3.ticks.color =
					BARCHART_DARK_COLORS.WHITE; // TVL

				newLoansHistoryChartOptions.scales.x.grid.color =
					BARCHART_DARK_COLORS.AXIS_LINE_COLOR;
				newLoansHistoryChartOptions.scales.y.grid.color =
					BARCHART_DARK_COLORS.AXIS_LINE_COLOR;

				if (loansHistoryData != null) {
					newLoanHistoryData = JSON.parse(JSON.stringify(loansHistoryData));

					// LTV
					newLoanHistoryData.datasets[0].backgroundColor =
						BARCHART_DARK_COLORS.LTV_BKG_COLOR;
					newLoanHistoryData.datasets[0].borderColor =
						BARCHART_DARK_COLORS.LTV_BORDER_COLOR;

					// Foreclosable
					newLoanHistoryData.datasets[2].backgroundColor =
						BARCHART_DARK_COLORS.FORECLOSABLE_BKG_COLOR;
					newLoanHistoryData.datasets[2].borderColor =
						BARCHART_DARK_COLORS.FORECLOSABLE_BORDER_COLOR;
				}

				resolve({ newLoanHistoryData, newLoansHistoryChartOptions });
				return;
			} else {
				let newLoanHistoryData = null;

				let newLoansHistoryChartOptions = cloneDeep(loansHistoryChartOptions);

				newLoansHistoryChartOptions.plugins.title.color =
					BARCHART_LIGHT_COLORS.BLACK;
				newLoansHistoryChartOptions.plugins.legend.labels.color =
					BARCHART_LIGHT_COLORS.BLACK;
				newLoansHistoryChartOptions.scales.x.ticks.color =
					BARCHART_LIGHT_COLORS.BLACK;
				newLoansHistoryChartOptions.scales.y3.ticks.color =
					BARCHART_LIGHT_COLORS.BLACK;

				newLoansHistoryChartOptions.scales.x.grid.color =
					BARCHART_LIGHT_COLORS.AXIS_LINE_COLOR;
				newLoansHistoryChartOptions.scales.y.grid.color =
					BARCHART_LIGHT_COLORS.AXIS_LINE_COLOR;

				if (loansHistoryData != null) {
					newLoanHistoryData = JSON.parse(JSON.stringify(loansHistoryData));

					// LTV
					newLoanHistoryData.datasets[0].backgroundColor =
						BARCHART_LIGHT_COLORS.LTV_BKG_COLOR;
					newLoanHistoryData.datasets[0].borderColor =
						BARCHART_LIGHT_COLORS.LTV_BORDER_COLOR;

					// Foreclosable loans black color
					newLoanHistoryData.datasets[2].backgroundColor =
						BARCHART_LIGHT_COLORS.FORECLOSABLE_BKG_COLOR;
					newLoanHistoryData.datasets[2].borderColor =
						BARCHART_LIGHT_COLORS.FORECLOSABLE_BORDER_COLOR;
				}

				resolve({ newLoanHistoryData, newLoansHistoryChartOptions });
			}
		} catch (error) {
			console.log("adjustBarTheme | error", error);
			resolve({ newGraphData: null, newChartOptions: null });
		}
	});
};

/**
 * Used to adjust the stats marketplace bar chart's theme to be light or dark
 * @param {*} graphData - existing graph data
 * @param {*} theme - bar chart theme to set (light or dark)
 * @returns updated graph data and chart options
 */
export const adjustMarketplaceStatsBarTheme = async (
	graphData,
	chartOptions,
	theme = "light"
) => {
	return new Promise((resolve) => {
		try {
			if (
				localStorage.getItem("theme") != null &&
				localStorage.getItem("theme") != theme
			) {
				theme = localStorage.getItem("theme");
			}

			if (theme == "dark") {
				let newGraphData = null;
				let newChartOptions = cloneDeep(chartOptions);

				/** Change title, y-axis title, x-axis title */
				newChartOptions.plugins.title.color = SCATTER_DARK_COLORS.WHITE;

				/** Change legend labels text color */
				newChartOptions.plugins.legend.labels.color = SCATTER_DARK_COLORS.WHITE;

				/** Change axis lines */
				newChartOptions.scales.x.grid.color =
					SCATTER_DARK_COLORS.AXIS_LINE_COLOR;
				newChartOptions.scales.y.grid.color =
					SCATTER_DARK_COLORS.AXIS_LINE_COLOR;

				/** Change y-axis and x-axis range values */
				newChartOptions.scales.x.ticks.color = SCATTER_DARK_COLORS.WHITE;
				newChartOptions.scales.y.ticks.color = SCATTER_DARK_COLORS.WHITE;

				if (graphData != null) {
					newGraphData = JSON.parse(JSON.stringify(graphData));
					newGraphData.datasets[0].backgroundColor = "rgba(45, 179, 255, 0.65)"; // Sharky
					newGraphData.datasets[1].backgroundColor = "rgba(255, 136, 75, 0.65)"; // Citrus
					newGraphData.datasets[2].backgroundColor =
						"rgba(150, 255, 114, 0.65)"; // Frakt
					newGraphData.datasets[3].backgroundColor =
						"rgba(253, 153, 255, 0.65)"; // Rain
				}

				resolve({
					newGraphData,
					newChartOptions,
				});
			} else {
				let newChartOptions = cloneDeep(chartOptions);
				let newGraphData = null;

				newChartOptions.plugins.title.color = SCATTER_LIGHT_COLORS.BLACK;
				newChartOptions.plugins.legend.labels.color =
					SCATTER_LIGHT_COLORS.BLACK;
				newChartOptions.scales.x.ticks.color = SCATTER_LIGHT_COLORS.BLACK;
				newChartOptions.scales.y.ticks.color = SCATTER_LIGHT_COLORS.BLACK;

				/** Change axis lines */
				newChartOptions.scales.x.grid.color =
					SCATTER_LIGHT_COLORS.AXIS_LINE_COLOR;
				newChartOptions.scales.y.grid.color =
					SCATTER_LIGHT_COLORS.AXIS_LINE_COLOR;

				if (graphData != null) {
					newGraphData = JSON.parse(JSON.stringify(graphData));
					newGraphData.datasets[0].backgroundColor = "rgba(45, 153, 255, 0.65)"; // Sharky
					newGraphData.datasets[1].backgroundColor = "rgba(255, 122, 0, 0.65)"; // Citrus
					newGraphData.datasets[2].backgroundColor = "rgba(105, 219, 65, 0.65)"; // Frakt
					newGraphData.datasets[3].backgroundColor = "rgba(7, 23, 81, 0.65)"; // Rain
				}

				resolve({
					newGraphData,
					newChartOptions,
				});
			}
		} catch (error) {
			console.log("adjustMarketplaceStatsBarTheme | error", error);
			resolve({ newGraphData: null, newChartOptions: null });
		}
	});
};
