import { COLLECTION_NAMES, LOAN_VALUES } from "../../values/constants";
import { getCollectionReference } from "../firebase.service";
import { adjustMarketplaceStatsBarTheme } from "../../services/features/chart-theme-service";

/**
 * Used to fetch marketplace daily stats
 * @returns list of marketplace daily stats and global volume
 */
export const fetchTodaysMarketplaceStats = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const statsList = [];
			let globalVolume = 0;

			const {
				globalVolume: yesterdayGlobalVolume,
				statsList: yesterdayStatsList,
			} = await fetchYesterdaysMarketplaceStats();

			const loanPlatforms = LOAN_VALUES;

			// Get the latest / today's loan marketplace stats
			for (let index = 0; index < loanPlatforms.length; index++) {
				const marketplaceRef = getCollectionReference(
					COLLECTION_NAMES.MARKETPLACE_DAILY_VOLUME
				);
				await marketplaceRef
					.where("active", "==", true)
					.where("marketplace", "==", loanPlatforms[index])
					.orderBy("createdAt", "desc")
					.limit(1)
					.get()
					.then((instance) => {
						if (instance.size > 0) {
							instance.forEach((collection) => {
								// ensures it gets yesterdays stats only
								const marketplaceData = collection.data();
								statsList.push(marketplaceData);
								globalVolume += marketplaceData.totalVolume;
							});
						}
					});
			}

			// Based on yesterday's stats, calculate the total loan volume change
			for (let i = 0; i < statsList.length; i++) {
				let volChange = 0;
				let marketDominanceChange = 0;
				let marketDominance = 0;

				if (globalVolume != 0 && !isNaN(statsList[i].totalVolume)) {
					marketDominance = Number(
						((statsList[i].totalVolume / globalVolume) * 100)?.toFixed(2)
					);
				}

				for (let j = 0; j < yesterdayStatsList.length; j++) {
					if (
						yesterdayStatsList[j]?.marketplace == statsList[i]?.marketplace &&
						yesterdayStatsList[j]?.totalVolume != 0 &&
						yesterdayStatsList[j]?.totalVolume
					) {
						volChange =
							(statsList[i].totalVolume / yesterdayStatsList[j].totalVolume -
								1) *
							100;
					}
					if (
						yesterdayStatsList[j]?.marketplace == statsList[i]?.marketplace &&
						yesterdayGlobalVolume != 0 &&
						yesterdayStatsList[j]?.totalVolume
					) {
						let yesterdayMarketDominance = Number(
							(
								(yesterdayStatsList[j]?.totalVolume / yesterdayGlobalVolume) *
								100
							)?.toFixed(2)
						);
						marketDominanceChange = Number(
							(marketDominance - yesterdayMarketDominance)?.toFixed(2)
						);
					}

					statsList[i].volChange = volChange;
					statsList[i].marketDominanceChange = marketDominanceChange;
				}
			}

			statsList.sort((m1, m2) => {
				return m2?.totalVolume - m1?.totalVolume;
			});

			resolve({ statsList, globalVolume });
		} catch (error) {
			console.log("fetchTodaysMarketplaceStats | error", error);
			reject(error);
		}
	});
};

/**
 * Used to fetch yesterday's marketplace daily stats
 * @returns list of marketplace's yesterday stats and yesterday global volume
 */
export const fetchYesterdaysMarketplaceStats = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const statsList = [];
			let globalVolume = 0;
			const loanPlatforms = LOAN_VALUES;

			for (let index = 0; index < loanPlatforms.length; index++) {
				const marketplaceRef = getCollectionReference(
					COLLECTION_NAMES.MARKETPLACE_DAILY_VOLUME
				);
				await marketplaceRef
					.where("active", "==", true)
					.where("marketplace", "==", loanPlatforms[index])
					.orderBy("createdAt", "desc")
					.limit(2)
					.get()
					.then((instance) => {
						if (instance.size > 0) {
							let i = 0;
							instance.forEach((collection) => {
								if (i == 1) {
									// ensures it gets yesterdays stats only
									const marketplaceData = collection.data();
									statsList.push(marketplaceData);
									globalVolume += marketplaceData.totalVolume;
								}
								i += 1;
							});
						}
					});
			}

			statsList.sort((m1, m2) => {
				return m2?.totalVolume - m1?.totalVolume;
			});
			resolve({ statsList, globalVolume });
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Used to fetch historical marketplace stats
 * @param {*} barOptions - bar graph chart options
 * @returns list of weekly stats, weekly global volume and total days of available data
 */
export const fetchHistoricalMarketplaceStats = async (barOptions, theme) => {
	return new Promise((resolve, reject) => {
		try {
			/** Creates a reference to the MBB holders collection in Firestore */
			const marketplaceRef = getCollectionReference(
				COLLECTION_NAMES.MARKETPLACE_DAILY_VOLUME
			);

			const sharkyStats = [];
			const citrusStats = [];
			const fraktStats = [];
			const rainStats = [];

			marketplaceRef
				.where("active", "==", true)
				.orderBy("createdAt", "asc")
				.get()
				.then((instance) => {
					if (instance.size > 0) {
						let i = 0;
						instance.forEach(async (marketplaceStats) => {
							const statsData = marketplaceStats.data();

							if (statsData.marketplace == "sharky") {
								sharkyStats.push({
									x: statsData.priceDate,
									y: statsData.totalVolume,
								});
							} else if (statsData.marketplace == "citrus") {
								citrusStats.push({
									x: statsData.priceDate,
									y: statsData.totalVolume,
								});
							} else if (statsData.marketplace == "frakt") {
								fraktStats.push({
									x: statsData.priceDate,
									y: statsData.totalVolume,
								});
							} else if (statsData.marketplace == "rain") {
								rainStats.push({
									x: statsData.priceDate,
									y: statsData.totalVolume,
								});
							}

							if (i == instance.size - 1) {
								// Ensures deep clone of chartOptions
								// let temp = JSON.parse(JSON.stringify(chartOptions));
								// temp.plugins.title.text = collection?.name + " Loans";

								const newData = {
									datasets: [
										{
											label: "Sharky Volume",
											data: sharkyStats,
											backgroundColor: "rgba(45, 153, 255, 0.65)",
										},
										{
											label: "Citrus Volume",
											data: citrusStats,
											backgroundColor: "rgba(255, 122, 0, 0.65)",
										},
										{
											label: "Banx Volume",
											data: fraktStats,
											backgroundColor: "rgba(105, 219, 65, 0.65)",
										},
										{
											label: "Rain Volume",
											data: rainStats,
											backgroundColor: "rgba(7, 23, 81, 0.65)",
										},
									],
								};

								const { newGraphData, newChartOptions } =
									await adjustMarketplaceStatsBarTheme(
										newData,
										barOptions,
										theme
									);

								resolve({
									newBarData: newGraphData,
									newBarOptions: newChartOptions,
								});
							}
							i += 1;
						});
					} else {
						// No loans data found for collection
						const newData = {
							datasets: [
								{
									label: "Sharky Volume",
									data: [],
									backgroundColor: "rgba(45, 153, 255, 0.65)",
								},
								{
									label: "Citrus Volume",
									data: [],
									backgroundColor: "rgba(255, 122, 0, 0.65)",
								},
								{
									label: "Banx Volume",
									data: [],
									backgroundColor: "rgba(105, 219, 65, 0.65)",
								},
								{
									label: "Rain Volume",
									data: [],
									backgroundColor: "rgba(7, 23, 81, 0.65)",
								},
							],
						};

						resolve({
							newBarData: newData,
							newBarOptions: null,
						});
					}
				});
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Used to fetch marketplace weekly stats
 * @returns list of marketplace weekly stats
 */
export const fetchWeeklyMarketplaceStats = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const weeklyStatsList = [];
			const loanPlatforms = LOAN_VALUES;
			let globalVolume = 0;
			let lastGlobalVolume = 0;
			let daysOfData = 7;

			for (let index = 0; index < loanPlatforms.length; index++) {
				const { weeklyStats, totalData } = await getWeeklyMarketplaceData(
					loanPlatforms[index]
				);
				if (totalData < daysOfData) {
					daysOfData = totalData;
				}
				const { lastWeeklyStats } = await getLastWeeksMarketplaceData(
					loanPlatforms[index]
				);

				lastGlobalVolume += lastWeeklyStats?.totalVolume;
				globalVolume += weeklyStats?.totalVolume;

				weeklyStats["lastWeeklyVolume"] = 0;
				weeklyStats["volChange"] = 0;

				if (
					weeklyStats?.totalVolume &&
					lastWeeklyStats?.totalVolume &&
					lastWeeklyStats.totalVolume != 0
				) {
					weeklyStats["lastWeeklyVolume"] = lastWeeklyStats.totalVolume;
					weeklyStats["volChange"] =
						(weeklyStats.totalVolume / lastWeeklyStats.totalVolume - 1) * 100;
				}

				weeklyStatsList.push(weeklyStats);
			}

			// Yesterday market dominance change
			for (let i = 0; i < weeklyStatsList.length; i++) {
				let marketDominanceChange = 0;
				if (
					weeklyStatsList[i]?.totalVolume &&
					weeklyStatsList[i]?.lastWeeklyVolume &&
					globalVolume != 0 &&
					lastGlobalVolume != 0
				) {
					let marketDominance = Number(
						((weeklyStatsList[i].totalVolume / globalVolume) * 100)?.toFixed(2)
					);

					let yesterdayMarketDominance = Number(
						(
							(weeklyStatsList[i].lastWeeklyVolume / lastGlobalVolume) *
							100
						)?.toFixed(2)
					);
					// FOR TESTING PURPOSES
					weeklyStatsList[i]["marketDominance"] = marketDominance;
					weeklyStatsList[i]["yesterdayMarketDominance"] =
						yesterdayMarketDominance;

					marketDominanceChange = Number(
						(marketDominance - yesterdayMarketDominance)?.toFixed(2)
					);
				}

				weeklyStatsList[i]["marketDominanceChange"] = marketDominanceChange;
			}

			weeklyStatsList.sort((m1, m2) => {
				return m2?.totalVolume - m1?.totalVolume;
			});

			resolve({
				weeklyStatsList,
				weeklyGlobalVolume: globalVolume,
				daysOfData,
			});
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Used to fetch marketplace weekly stats for a specific marketplace
 * @param loanMarketplace selected loan marketplace
 * @returns a marketplace's weekly stats and total days of data
 */
export const getWeeklyMarketplaceData = (loanMarketplace) => {
	return new Promise(async (resolve, reject) => {
		try {
			const weeklyStats = {
				marketplace: loanMarketplace,
				totalLoans: 0,
				totalVolume: 0,
				uniqueBorrowers: 0,
				uniqueLenders: 0,
			};

			/** Creates a reference to the marketplace collection in Firestore */
			const marketplaceRef = getCollectionReference(
				COLLECTION_NAMES.MARKETPLACE_DAILY_VOLUME
			);

			marketplaceRef
				.where("active", "==", true)
				.where("marketplace", "==", loanMarketplace)
				.orderBy("createdAt", "desc")
				.limit(7)
				.get()
				.then((instance) => {
					if (instance.size > 0) {
						instance.forEach((collection) => {
							const marketplaceData = collection.data();

							weeklyStats.totalLoans += marketplaceData.totalLoans;
							weeklyStats.totalVolume += marketplaceData.totalVolume;
							weeklyStats.uniqueBorrowers += marketplaceData.uniqueBorrowers;
							weeklyStats.uniqueLenders += marketplaceData.uniqueLenders;
						});

						resolve({ weeklyStats, totalData: instance.size });
					} else {
						resolve({ weeklyStats, totalData: instance.size });
					}
				});
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Used to fetch last week's marketplace stats for a specific marketplace
 * @param loanMarketplace selected loan marketplace
 * @returns a marketplace's weekly stats and total days of data
 */
export const getLastWeeksMarketplaceData = (loanMarketplace) => {
	return new Promise(async (resolve, reject) => {
		try {
			const weeklyStats = {
				marketplace: loanMarketplace,
				totalLoans: 0,
				totalVolume: 0,
				uniqueBorrowers: 0,
				uniqueLenders: 0,
			};

			/** Creates a reference to the marketplace collection in Firestore */
			const marketplaceRef = getCollectionReference(
				COLLECTION_NAMES.MARKETPLACE_DAILY_VOLUME
			);

			marketplaceRef
				.where("active", "==", true)
				.where("marketplace", "==", loanMarketplace)
				.orderBy("createdAt", "desc")
				.limit(14)
				.get()
				.then((instance) => {
					if (instance.size > 0) {
						let i = 0;
						instance.forEach((collection) => {
							if (i >= 7) {
								const marketplaceData = collection.data();

								weeklyStats.totalLoans += marketplaceData.totalLoans;
								weeklyStats.totalVolume += marketplaceData.totalVolume;
								weeklyStats.uniqueBorrowers += marketplaceData.uniqueBorrowers;
								weeklyStats.uniqueLenders += marketplaceData.uniqueLenders;
							}
							i += 1;
						});

						resolve({
							lastWeeklyStats: weeklyStats,
						});
					} else {
						resolve({
							lastWeeklyStats: weeklyStats,
						});
					}
				});
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Used to fetch marketplace daily stats
 * @param loanMarketplace selected loan marketplace
 * @returns list of marketplace daily stats
 */
export const getDailyMarketplaceData = (loanMarketplace) => {
	return new Promise(async (resolve, reject) => {
		try {
			/** Creates a reference to the marketplace collection in Firestore */
			const marketplaceRef = getCollectionReference(
				COLLECTION_NAMES.MARKETPLACE_DAILY_VOLUME
			);

			marketplaceRef
				.where("active", "==", true)
				.where("marketplace", "==", loanMarketplace)
				.orderBy("createdAt", "desc")
				.limit(1)
				.get()
				.then((instance) => {
					if (instance.size > 0) {
						instance.forEach((collection) => {
							const marketplaceData = collection.data();
							resolve(marketplaceData);
						});
					} else {
						resolve(null);
					}
				});
		} catch (error) {
			reject(error);
		}
	});
};
