export const LOAN_PLATFORMS = [
	{ value: "all", label: "All Loans 🌐" },
	{ value: "sharky", label: "Sharky Loans  🦈" },
	{ value: "citrus", label: "Citrus Loans  🟠" },
	{ value: "frakt", label: "Banx Loans  ⬛️" },
	{ value: "rain", label: "Rain Loans  💧" },
];

export const COLLECTION_NAMES = {
	COLLECTION_HASHLISTS: "collectionHashlists",
	SOLANA_COLLECTIONS: "solanaCollections",
	NFT_LOANS: "nftLoans",
	GROUPED_NFT_LOANS: "groupedNftLoans",
	LOANS_HISTORY: "loansHistory",
	MARKETPLACE_DAILY_VOLUME: "marketplaceDailyVolume",
};

export const LAMPORTS_TO_SOL = {
	DECIMALS: 1000000000,
};

export const GRAPH_TYPES = [
	{ value: "all", label: "All Charts  🖥" },
	{ value: "scatter", label: "Loans Chart  📊" },
	{ value: "line", label: "Leverage Chart  📈" },
];

export const LOAN_VALUES = ["sharky", "citrus", "frakt", "rain"];

export const INITIAL_GRAPH_OPTIONS = {
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		y: {
			beginAtZero: false,
			title: {
				display: true,
				text: "Price",
			},
			grid: {
				display: true,
				color: "#e5e7eb",
			},
			ticks: {
				display: true,
				color: "#666667",
			},
		},
		x: {
			title: {
				display: true,
				text: "Days until Expiration",
			},
			min: -2,
			grid: {
				display: true,
				color: "#e5e7eb",
			},
			ticks: {
				display: true,
				color: "#666667",
			},
		},
	},
	plugins: {
		title: {
			display: true,
			text: "Loans",
		},
		legend: {
			display: true,
			labels: {
				color: "#000",
			},
		},
		annotation: {
			display: true,
		},
	},
};

export const INITIAL_LOAN_HISTORY_GRAPH_OPTIONS = {
	responsive: true,
	interaction: {
		mode: "index",
		intersect: false,
	},

	stacked: false,
	plugins: {
		title: {
			display: true,
			text: "Collection LTV vs TVL vs Floor Price",
		},
		legend: {
			display: true,
			labels: {
				color: "#000",
			},
		},
		annotation: {
			display: true,
		},
	},

	scales: {
		x: {
			ticks: {
				display: true,
				color: "#666667",
			},
			grid: {
				display: true,
				color: "#e5e7eb",
			},
		},
		// FP
		y: {
			type: "linear",
			display: true,
			position: "left",
			ticks: {
				display: true,
				color: "rgba(133, 53, 235)",
			},
			grid: {
				display: false,
				color: "#fff",
				drawOnChartArea: false,
			},
		},
		// LTV
		y1: {
			type: "linear",
			display: true,
			position: "left",

			min: 0,
			suggestedMax: 100,
			ticks: {
				display: true,
				color: "rgba(255, 99, 132)",
			},
			grid: {
				display: false,
				color: "#e5e7eb",
				drawOnChartArea: false,
			},
		},
		// TVL
		y2: {
			type: "linear",
			display: true,
			position: "right",
			grid: {
				drawOnChartArea: false,
				display: false,
				color: "#e5e7eb",
			},
			ticks: {
				display: true,
				color: "rgba(53, 162, 235)",
			},
			border: {
				display: false,
			},

			beginAtZero: false,
		},

		// Foreclosable
		y3: {
			type: "linear",
			display: true,
			position: "left",

			ticks: {
				display: true,
				color: "#666667",
			},
			border: {
				display: false,
			},
			grid: {
				display: false,
			},

			beginAtZero: false,
		},
	},
};

export const INITIAL_GLOBAL_LOAN_HISTORY_GRAPH_OPTIONS = {
	responsive: true,
	interaction: {
		mode: "index",
		intersect: false,
	},

	stacked: false,
	plugins: {
		title: {
			display: true,
			text: "Collection LTV vs TVL vs Floor Price",
		},
		legend: {
			display: true,
			labels: {
				color: "#000",
			},
		},
	},
	scales: {
		x: {
			ticks: {
				display: true,
				color: "#666667",
			},
			grid: {
				display: false,
				color: "#e5e7eb",
			},
		},

		// FP
		y: {
			type: "linear",
			display: true,
			position: "left",
			ticks: {
				color: "rgba(133, 53, 235)",
			},
			grid: {
				display: true,
				color: "#e5e7eb",
				drawOnChartArea: false,
			},
		},
		// LTV
		y1: {
			type: "linear",
			display: true,
			position: "left",
			suggestedMin: 70,
			suggestedMax: 100,
			ticks: {
				color: "rgba(255, 99, 132)",
			},
			grid: {
				display: false,
				color: "#e5e7eb",
				drawOnChartArea: false,
			},
		},
		// TVL
		y2: {
			type: "linear",
			display: true,
			position: "right",
			grid: {
				display: false,
				drawOnChartArea: false,
			},
			ticks: {
				color: "rgba(53, 162, 235)",
			},
			beginAtZero: false,
		},

		y3: {
			type: "linear",
			display: true,
			position: "left",

			ticks: {
				display: true,
				color: "#666667",
			},
			border: {
				display: false,
			},
			grid: {
				display: false,
			},

			beginAtZero: false,
		},
	},
};

export const MARKETPLACE_STATS_BAR_OPTIONS = {
	plugins: {
		title: {
			display: true,
			text: "Daily Loan Volume by Marketplace",
		},
		legend: {
			display: true,
			labels: {
				color: "#666667",
			},
		},
	},
	responsive: true,
	scales: {
		x: {
			stacked: true,
			ticks: {
				display: true,
				color: "#666667",
			},
			grid: {
				display: true,
				color: "#e5e7eb",
			},
		},
		y: {
			stacked: true,
			//suggestedMax: 80000,
			ticks: {
				display: true,
				color: "#666667",
			},
			grid: {
				display: true,
				color: "#e5e7eb",
			},
		},
	},
};
