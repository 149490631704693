export const RARITY_LOGOS = {
	HOWRARE_IS: require("../assets/images/howrareis-logo.png"),
	MOONRANK: require("../assets/images/moonrank-logo.png"),
	HYPERSPACE: require("../assets/images/hyperspace-logo.png"),
};

export const LOAN_PLATFORM_LOGOS = {
	SHARKY: require("../assets/images/sharky.png"),
	CITRUS: require("../assets/images/citrus.png"),
	FRAKT: require("../assets/images/frakt.png"),
	RAIN: require("../assets/images/rain.png"),
};
