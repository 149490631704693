import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import {
	LOAN_PLATFORMS,
	INITIAL_GRAPH_OPTIONS,
	INITIAL_LOAN_HISTORY_GRAPH_OPTIONS,
} from "../../values/constants";
import { LIGHT_SELECT_STYLES, DARK_SELECT_STYLES } from "../../values/theme";
import { useParams, NavLink } from "react-router-dom";
import { fetchCollection } from "../../services/features/collection-service";
import {
	adjustScatterTheme,
	adjustBarTheme,
} from "../../services/features/chart-theme-service";

import { Scatter, Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import MbbLogo from "../../assets/images/monkey_baby_business_logo.png";
import StatsList from "../overview-loans/StatsList";
import { Bars } from "react-loader-spinner";
import { BsDownload, BsFillInfoCircleFill } from "react-icons/bs";
import useWindowDimensions from "../../services/utils/Dimensions";
import Select from "react-select";
import { fetchLoansByCollection } from "../../services/features/loans-service";
import { fetchLoansHistoryByCollection } from "../../services/features/loans-history-service";
import ReactGA from "react-ga4";
import html2canvas from "html2canvas";
import { Tooltip as ReactTooltip } from "react-tooltip";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip,
	Legend,
	Title,
} from "chart.js";

import moment from "moment";

ChartJS.register(
	LinearScale,
	CategoryScale,
	PointElement,
	LineElement,
	Tooltip,
	Legend,
	Title,
	annotationPlugin
);

export const LoanDetailsPage = ({ theme }) => {
	let { magicEdenSlug } = useParams();

	const [collection, setCollection] = useState([]);

	// Components each collection
	const [graphData, setGraphData] = useState(null);
	const [quickStats, setQuickStats] = useState(null);
	const [chartOptions, setChartOptions] = useState(INITIAL_GRAPH_OPTIONS);
	const [invalidCollection, setInvalidCollection] = useState(false);
	const [loansHistoryData, setLoansHistoryData] = useState(null);
	const [loansHistoryChartOptions, setLoansHistoryChartOptions] = useState(
		INITIAL_LOAN_HISTORY_GRAPH_OPTIONS
	);
	const [changingPlatform, setChangingPlatform] = useState(false);
	const [viewFullDescription, setViewFullDescription] = useState(false);

	const [loanPlatform, setLoanPlatform] = useState({
		value: "all",
		label: "All Loans 🌐",
	});
	const windowDimensions = useWindowDimensions();

	useEffect(() => {
		window.scrollTo(0, 0);
		setViewFullDescription(false);
		getCollection();
	}, [magicEdenSlug]);

	const getCollection = async () => {
		const { col, invalid } = await fetchCollection(magicEdenSlug);
		// // Saves pageview to analytics
		ReactGA.send({
			hitType: "pageview",
			page_title: col?.name ? col?.name + " Details Page" : "N/A Details Page",
			collection_name: col?.name ? col?.name : "N/A",
			invalid_collection_req: invalid,
			page: window.location.pathname + window.location.search,
		});
		setCollection(col);
		setInvalidCollection(invalid);
	};

	useEffect(() => {
		if (collection != null && collection?.name) {
			getLoans();
		}
	}, [collection, loanPlatform]);

	useEffect(() => {
		if (collection != null && collection?.name && quickStats) {
			getLoansHistory();
		}
	}, [collection, quickStats]);

	useEffect(() => {
		switchTheme();
	}, [theme]);

	const getLoans = async () => {
		setChangingPlatform(true);
		const { newQuickStats, newGraphData, newChartOptions } =
			await fetchLoansByCollection(
				collection,
				loanPlatform.value,
				chartOptions,
				theme
			);
		if (newChartOptions) {
			setChartOptions(newChartOptions);
		}
		setQuickStats(newQuickStats);
		setGraphData(newGraphData);
		setChangingPlatform(false);
	};

	const getLoansHistory = async () => {
		const { newHistoryData, newHistoryOptions } =
			await fetchLoansHistoryByCollection(
				collection,
				loansHistoryChartOptions,
				quickStats,
				theme
			);
		setLoansHistoryData(newHistoryData);
		setLoansHistoryChartOptions(newHistoryOptions);
	};

	/** Used to build the chart into a canvas and
	 * download the chart to the user's device */
	const exportChart = async (imageFileName, elementId) => {
		try {
			//setLoadingDownloadImage(true);
			// document.getElementById("details-stamp").style = "visibility:visible;";
			const el = document.getElementById(elementId);

			const canvas = await html2canvas(el, {
				useCORS: true,
				scale: 5,
				backgroundColor: theme === "light" ? "#fff" : "#1E1F25",
			});
			const image = canvas.toDataURL("image/png", 1.0);
			downloadImage(image, imageFileName);
		} catch (error) {
			document.getElementById("loan-platform-toggle").style = "display:flex;";
			//setLoadingDownloadImage(false);
		}
	};
	/** Downloads the image to the user's device */
	const downloadImage = (blob, fileName) => {
		const fakeLink = window.document.createElement("a");
		fakeLink.style = "display:none;";
		fakeLink.download = fileName;

		fakeLink.href = blob;

		document.body.appendChild(fakeLink);
		fakeLink.click();
		document.body.removeChild(fakeLink);

		fakeLink.remove();
		document.getElementById("loan-platform-toggle").style = "display:flex;";
		// document.getElementById("details-stamp").style = "visibility:hidden;";
		//setLoadingDownloadImage(false);
	};

	const switchTheme = async () => {
		if (graphData != null) {
			const { newGraphData, newChartOptions } = await adjustScatterTheme(
				graphData,
				chartOptions,
				theme
			);
			if (newGraphData) {
				setGraphData(newGraphData);
			}
			if (newChartOptions) {
				setChartOptions(newChartOptions);
			}
		}

		if (loansHistoryData != null) {
			const { newLoanHistoryData, newLoansHistoryChartOptions } =
				await adjustBarTheme(loansHistoryData, loansHistoryChartOptions, theme);

			if (newLoanHistoryData) {
				setLoansHistoryData(newLoanHistoryData);
			}
			if (newLoansHistoryChartOptions) {
				setLoansHistoryChartOptions(newLoansHistoryChartOptions);
			}
		}
	};

	return (
		<div className="bg-white dark:bg-light-black">
			<Helmet>
				<title>{`${window.location.href}`}</title>
				<meta charSet="utf-8" />
				<meta
					name="description"
					content={`${window.location.href} - NFT loans analytics.`}
				/>
			</Helmet>

			{collection.name && (
				<Helmet>
					<title>{`${collection.name} - NFT Loans Analytics`}</title>
					<meta charSet="utf-8" />
					<meta
						name="description"
						content={`View ${collection.name} NFT loans analytics. ${
							collection?.description ? collection?.description : ""
						}`}
					/>
				</Helmet>
			)}

			{invalidCollection ? (
				<div className="flex flex-col justify-start items-center pt-4 h-screen">
					<h1 className="text-5xl font-cubano font-bold pt-6 text-dark-blue dark:text-white">
						404
					</h1>
					<h1 className="text-3xl font-cubano font-bold pt-4 text-dark-blue dark:text-white">
						This page is lost.
					</h1>
					<p className="text-lg pt-4 font-roboto text-black dark:text-cloud-gray">
						We can't find the page you were looking for.
					</p>
				</div>
			) : (
				<div className="flex flex-col justify-start items-center mt-4 w-full">
					<div
						id={"details-chart"}
						className="flex flex-col w-full p-2"
						style={{ maxWidth: "1000px" }}
					>
						<div className="flex flex-col lg:flex-row justify-center lg:justify-between w-full items-center lg:items-start">
							<div className="lg:w-1/3"></div>
							<div className="mt-2">
								<div className="flex flex-col" style={{ maxWidth: "345px" }}>
									<div className="flex flex-row items-center mb-2">
										<>
											<img
												src={
													collection?.imageUrl
														? "https://res.cloudinary.com/dsssgubl9/image/fetch/w_200,h_200,c_fill/" +
														  encodeURI(collection?.imageUrl)
														: "https://res.cloudinary.com/dsssgubl9/image/fetch/w_200,h_200,c_fill/" +
														  encodeURI(
																"https://creator-hub-prod.s3.us-east-2.amazonaws.com/unknownabstracts_pfp_1650063070880.png"
														  )
												}
												alt={collection?.name + " collection image"}
												className="border	border-black rounded-full object-contain h-12 w-12 mr-2"
											/>

											<h1 className="text-lg font-roboto font-bold text-dark-blue dark:text-white">
												{collection?.name}
											</h1>
										</>

										<a
											data-tooltip-id="my-tooltip"
											data-tooltip-content={
												"Last Updated Loans: " +
												moment(collection?.oldestLoansUpdate * 1000).fromNow()
											}
											//className="ml-3 lg:ml-2"
											style={{
												paddingLeft: "12px",
												paddingRight: "12px",
												paddingTop: "10px",
												paddingBottom: "10px",
											}}
										>
											<BsFillInfoCircleFill color="#818181" size={12} />
										</a>
									</div>

									{windowDimensions.width <= 1024 && (
										<>
											<h2
												className="text-sm font-roboto text-dark-blue dark:text-cloud-gray px-2 mt-1 mb-3"
												style={{ textAlign: "left" }}
											>
												{collection?.description?.length > 180 &&
												!viewFullDescription
													? collection?.description.substring(0, 175) +
													  " ...   "
													: collection?.description + " "}
												{collection?.description?.length > 180 && (
													<button
														onClick={() => {
															setViewFullDescription(!viewFullDescription);
														}}
														className="text-sm font-roboto text-pink underline"
													>
														{!viewFullDescription ? "Read More" : "Read Less"}
													</button>
												)}
											</h2>
										</>
									)}
								</div>
								<StatsList {...{ collection, quickStats }} />
							</div>
							<div className="lg:w-1/3 lg:flex lg:justify-end lg:mt-12 text-center font-roboto font-normal">
								<div
									style={{
										maxWidth: "250px",
										zIndex: 1001,
										alignItems: "start",
									}}
									className="flex flex-col mt-2 lg:mt-0 w-full"
									id={"loan-platform-toggle"}
								>
									<Select
										options={LOAN_PLATFORMS}
										onChange={(e) => {
											if (e.value != loanPlatform.value) {
												setLoanPlatform(e);
											}
										}}
										isSearchable={false}
										styles={
											theme === "dark"
												? DARK_SELECT_STYLES
												: LIGHT_SELECT_STYLES
										}
										className="z-[1001]"
										value={loanPlatform}
									/>
									{windowDimensions.width > 1024 && (
										<>
											<h2
												className="text-sm font-roboto text-dark-blue dark:text-cloud-gray mt-4"
												style={{ textAlign: "left" }}
											>
												{collection?.description?.length > 180 &&
												!viewFullDescription
													? collection?.description.substring(0, 175) +
													  " ...   "
													: collection?.description + " "}
												{collection?.description?.length > 180 && (
													<button
														onClick={() => {
															setViewFullDescription(!viewFullDescription);
														}}
														className="text-sm font-roboto text-pink underline"
													>
														{!viewFullDescription ? "Read More" : "Read Less"}
													</button>
												)}
											</h2>
										</>
									)}
								</div>
							</div>
						</div>

						{graphData ? (
							<div
								style={{
									position: "relative",
									width: "100%",
									height: "100%",
									minHeight: "65vh",
									paddingHorizontal: "12px",
									display: "flex",
									justifyContent: "center",
									paddingBottom: 24,
									maxWidth: "1000px",
									margin: "auto",
									zIndex: 1000,
								}}
							>
								<a
									data-tooltip-id="underwater-tooltip"
									data-tooltip-content={
										"Citrus provides rarity-based loans which may impact underwater loans. Hover over plots for more info."
									}
									className="absolute z-[21] px-[12px] py-[8px]
									top-[0%] translate-y-[-0%] right-[6%] translate-x-[6%] mt-9
									md:right-[32%] md:translate-x-[-32%] md:mt-[2px]
									lg:right-[23%] lg:translate-x-[-23%] lg:mt-9"
								>
									{" "}
									<BsFillInfoCircleFill className="text-dark-gray" size={12} />
								</a>
								<Scatter
									options={chartOptions}
									data={graphData}
									height={"100%"}
									width={"100%"}
									style={{
										maxWidth: "1000px",
									}}
									// className={changingPlatform && "animate-pulse opacity-10"}
								/>
								<img
									src={MbbLogo}
									width={200}
									height={100}
									alt="logo"
									style={{
										position: "absolute",
										opacity: 0.125,
										zIndex: -10,
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
									}}
								/>
								{/* <div
										id={"details-stamp"}
										style={{
											position: "absolute",
											zIndex: -1,
											bottom: "2%",
										}}
									>
										<p
											className="font-roboto text-pink"
											style={{
												fontSize: 10,
												paddingLeft: 24,
											}}
										>
											Source: https://loans.monkeybaby.business
										</p>
									</div> */}
							</div>
						) : (
							<div
								style={{
									position: "relative",
									width: "100%",
									height: "100%",
									minHeight: "65vh",
									paddingHorizontal: "12px",
									display: "flex",
									justifyContent: "center",
									paddingBottom: 24,
									maxWidth: "1000px",
									margin: "auto",
									zIndex: 10,
								}}
							>
								<Bars
									height="50"
									width="50"
									color="#e41e8e"
									ariaLabel="bars-loading"
									wrapperStyle={{
										position: "absolute",
										top: "40%",
										left: "50%",
										transform: "translate(-50%, -50%)",
									}}
									wrapperClass=""
									visible={true}
								/>
							</div>
						)}
						<ReactTooltip
							id="underwater-tooltip"
							className="dark:!bg-white dark:!text-black font-roboto font-medium z-[1002]"
							style={{
								whiteSpace: "pre-line",
								width: "400px",
								maxWidth: "90%",
							}}
						/>
					</div>

					{graphData && (
						<div
							className="flex flex-row w-full justify-end px-4 pt-1 pb-2"
							style={{
								maxWidth: "1000px",
							}}
						>
							<button
								className="flex justify-start items-center ml-4"
								style={{ marginTop: -2 }}
								onClick={() => {
									exportChart(
										collection?.name + "-mbb-loans-chart.png",
										"details-chart"
									);
								}}
							>
								<BsDownload color="#e41e8e" size={12} />
								<p className="text-xs font-roboto text-pink ml-1">
									Download Chart
								</p>
							</button>
						</div>
					)}

					<div
						style={{
							position: "relative",
							width: "100%",
							height: "100%",
							//minHeight: "65vh",
							paddingHorizontal: "12px",
							display: "flex",
							justifyContent: "center",
							paddingBottom: 24,
							//backgroundColor: "#E5E54D",
							maxWidth: "1000px",
							margin: "auto",
							zIndex: 10,
						}}
						id={"leverage-chart"}
					>
						{loansHistoryData ? (
							<>
								<Line
									options={loansHistoryChartOptions}
									data={loansHistoryData}
									height={"100%"}
									width={"100%"}
									style={{
										maxWidth: "1000px",
										maxHeight: "400px",
										//minHeight: "300px",
									}}
								/>
								<img
									src={MbbLogo}
									width={200}
									height={100}
									alt="logo"
									style={{
										position: "absolute",
										opacity: 0.125,
										zIndex: -1,
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
									}}
								/>
								<div
									id={"details-stamp"}
									style={{
										position: "absolute",
										zIndex: -1,
										bottom: "0%",
									}}
								>
									<p
										className="font-roboto text-pink"
										style={{
											fontSize: 10,
											paddingLeft: 24,
										}}
									>
										Source: https://loans.monkeybaby.business
									</p>
								</div>
							</>
						) : (
							<div style={{ minHeight: "65vh" }}>
								<Bars
									height="50"
									width="50"
									color="#e41e8e"
									ariaLabel="bars-loading"
									wrapperStyle={{
										position: "absolute",
										top: "40%",
										left: "50%",
										transform: "translate(-50%, -50%)",
									}}
									wrapperClass=""
									visible={true}
								/>
							</div>
						)}
					</div>
					{loansHistoryData && (
						<div
							className="flex flex-row w-full justify-end px-4 pt-1 pb-2"
							style={{
								maxWidth: "1000px",
							}}
						>
							<button
								className="flex justify-start items-center ml-4"
								style={{ marginTop: -2 }}
								onClick={() => {
									exportChart(
										collection?.name + "-mbb-leverage-chart.png",
										"leverage-chart"
									);
								}}
							>
								<BsDownload color="#e41e8e" size={12} />
								<p className="text-xs font-roboto text-pink ml-1">
									Download Chart
								</p>
							</button>
						</div>
					)}

					<NavLink
						to="/"
						className="rounded-xl mt-2 mb-16 truncate bg-pink justify-center items-center flex text-white font-cubano"
						style={{ padding: "12px 24px", height: "45px", width: "150px" }}
					>
						<p>Close Chart</p>
					</NavLink>
				</div>
			)}
		</div>
	);
};

export default LoanDetailsPage;
