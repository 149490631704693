import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MbbLogo from "../../assets/images/monkey_baby_business_logo.png";
import HelloMoonLogo from "../../assets/images/powered-by-hello-moon.png";
import HelloMoonLogoDark from "../../assets/images/powered-by-hello-moon-dark.png";
import SearchBar from "./SearchBar";
import { IoClose, IoCloseSharp } from "react-icons/io5";
import algoliasearch from "algoliasearch";
import Switch from "react-switch";
import { BsFillMoonFill, BsFillSunFill } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";

import {
	TOOLS_DROPDOWN,
	MONKEY_BABY_DROPDOWN,
	LOANS_DROPDOWN,
} from "../../values/dropdowns";

import DropdownMenu from "./DropdownMenu";
import DropdownMobileMenu from "./DropdownMobileMenu";
import useWindowDimensions from "../../services/utils/Dimensions";
import MobileFullscreenMenu from "./MobileFullscreenMenu";

export const UniversalHeader = ({ theme, setTheme }) => {
	const algoliaClient = algoliasearch(
		"84KAITT1O4",
		"4fbf66cb434ec0f11ccf1dbbe2b1fd6d"
	);

	const [searchQuery, setSearchQuery] = useState("");
	const navigate = useNavigate();

	const clearSearch = () => {
		setSearchQuery("");
		setSearchResults([]);
		navigate({ pathname: "/" });
	};
	const [alertDisplay, setAlertDisplay] = useState(true);
	const alertMessage = "We are currently undergoing maintenance and data may be stale. We are working to fix this.";
	const [searchResults, setSearchResults] = useState([]);
	const [viewDropdown, setViewDropdown] = useState(false);
	const [viewMobileMenu, setViewMobileMenu] = useState(false);
	const windowDimensions = useWindowDimensions();

	const searchAlgolia = (keyword) => {
		if (keyword == "") {
			setSearchResults([]);
			return;
		} else {
			const collectionIndex = algoliaClient.initIndex("solanaCollections");

			collectionIndex
				.search(keyword, {
					hitsPerPage: 10,
					numericFilters: ["active = 1"],
				})
				.then(({ hits }) => {
					setSearchResults(hits);
				});
		}
	};

	useEffect(() => {
		if (windowDimensions.width <= 1024) {
			if (viewMobileMenu) {
				document.body.style.position = "fixed";
			} else {
				document.body.style.position = "static";
			}
		}
	}, [viewMobileMenu]);

	useEffect(() => {
		if (windowDimensions.width > 1024 && viewMobileMenu == true) {
			setViewMobileMenu(false);
			document.body.style.position = "static";
		}
	}, [windowDimensions]);

	return (
		<>
			{!viewMobileMenu || windowDimensions.width > 1024 ? (
				<div>
					<div className="flex flex-col justify-center font-cubano items-center shadow-md dark:shadow-black px-10 py-4 md:py-0 md:flex-row md:h-24 bg-white dark:bg-light-black">
						<div className="flex flex-row justify-center items-center">
							<div className="absolute left-6 lg:hidden">
								<button
									onClick={() => {
										setViewMobileMenu(true);
									}}
									className="flex lg:hidden text-gray-600 dark:text-white"
								>
									<FiMenu size={26} />
								</button>
							</div>

							<button
								onClick={() => {
									clearSearch();
									setSearchResults([]);
								}}
								className="flex flex-row items-center pl-6 md:pl-0 lg:mr-4"
							>
								<img
									src={MbbLogo}
									width={135}
									height={50}
									alt="Monkey Baby Business logo"
								/>
								<img
									src={theme === "light" ? HelloMoonLogo : HelloMoonLogoDark}
									width={90}
									height={45}
									alt="HelloMoon logo"
									className="flex ml-4 md:hidden lg:ml-0 object-contain"
								/>
							</button>
						</div>
						<div className="flex flex-col items-start justify-start">
							<SearchBar
								{...{
									searchQuery,
									setSearchQuery,
									clearSearch,
									searchAlgolia,
									setSearchResults,
									searchResults,
								}}
							/>
						</div>

						<div className="lg:flex hidden justify-center items-center md:w-2/4 mt-4 md:mt-0">
							<DropdownMenu
								title={"Loans"}
								dropdownItems={LOANS_DROPDOWN}
								externalLinks={false}
								{...{ setSearchQuery, setSearchResults }}
							/>

							<DropdownMenu
								title={"Monkey Babies"}
								dropdownItems={MONKEY_BABY_DROPDOWN}
								externalLinks={true}
								{...{ setSearchQuery, setSearchResults }}
							/>

							<DropdownMenu
								title={"Tools"}
								dropdownItems={TOOLS_DROPDOWN}
								externalLinks={true}
								{...{ setSearchQuery, setSearchResults }}
							/>

							<img
								src={theme === "light" ? HelloMoonLogo : HelloMoonLogoDark}
								width={125}
								height={50}
								alt="logo"
								className="hidden lg:ml-6 xl:flex lg:mr-4"
							/>

							<Switch
								checked={theme == "light"}
								onChange={() => {
									if (theme === "dark") {
										localStorage.setItem("theme", "light");
										setTheme("light");
										return;
									} else {
										localStorage.setItem("theme", "dark");
										setTheme("dark");
									}
								}}
								onColor={"#e41e8e"}
								offColor={"#04004f"}
								checkedIcon={false}
								uncheckedIcon={false}
								handleDiameter={25}
								width={65}
								height={30}
								checkedIcon={
									<div
										style={{
											height: "30px",
											width: "32px",
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
										}}
									>
										<BsFillSunFill color={"#fff"} size={16} />
									</div>
								}
								uncheckedIcon={
									<div
										style={{
											height: "30px",
											width: "32px",
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
										}}
									>
										<BsFillMoonFill color={"#fff"} size={16} />
									</div>
								}
							/>
						</div>
					</div>
					{alertDisplay && (
						<div
							className="flex flex-row justify-around items-center shadow-md py-1"
							style={{ backgroundColor: "#F1F3F4" }}
						>
							<button
								onClick={() => {
									setAlertDisplay(false);
								}}
								style={{
									minWidth: 20,
									marginLeft: 4,
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<IoCloseSharp color="#818181" size={20} />
							</button>

							<p className="font-roboto font-medium	text-md text-dark-gray text-center">
								{alertMessage}
							</p>
							<p style={{ minWidth: 10 }} className="hidden md:flex"></p>
						</div>
					)}
				</div>
			) : (
				<>
					<MobileFullscreenMenu
						{...{
							theme,
							setTheme,
							setSearchQuery,
							setSearchResults,
							setViewMobileMenu,
						}}
					/>
				</>
			)}
		</>
	);
};

export default UniversalHeader;
