import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { NavLink } from "react-router-dom";

export const DropdownMobileMenu = ({
	title,
	dropdownItems,
	externalLinks,
	setSearchQuery,
	setSearchResults,
	setViewMobileMenu,
}) => {
	const [viewDropdown, setViewDropdown] = useState(false);

	return (
		<div className="py-2 w-full">
			<div className="flex flex-col items-center justify-center text-left w-full">
				<button
					onClick={() => {
						setViewDropdown(!viewDropdown);
					}}
					className="inline-flex flex-row justify-center w-full px-2 py-2 focus:outline-none"
				>
					<div className="flex flex-row items-center text-dark-blue dark:text-white">
						<p className="mr-1 font-cubano text-lg">{title}</p>
						<IoIosArrowDown size={18} />
					</div>
				</button>
				<div
					className={`flex overflow-hidden left-0 w-full mt-2 justify-center items-center bg-gray-50 dark:bg-black text-center z-[300]`}
					style={
						viewDropdown
							? { maxHeight: "425px", transition: "max-height 0.25s ease-out" }
							: { maxHeight: "0px", transition: "max-height 0.25s ease-in" }
					}
				>
					<div className="py-1">
						{dropdownItems.map((item, index) => (
							<>
								{externalLinks ? (
									<a
										href={item.url}
										target={`${item.currentSite ? "" : "_blank"}`}
										className={`flex flex-row justify-center items-center px-4 py-3 text-lg text-center text-gray-700
										 dark:text-white hover:text-pink dark:hover:text-pink
										 ${item.currentSite ? `text-pink` : ``}`}
										key={index}
										onClick={() => {
											setSearchQuery("");
											setSearchResults([]);
											setViewMobileMenu(false);
										}}
									>
										{item?.icon && item?.icon != "" && (
											<img
												style={{
													width: "20px",
													height: "20px",
													marginRight: "8px",
													objectFit: "contain",
												}}
												src={item.icon}
											/>
										)}
										{item.label}
									</a>
								) : (
									<NavLink
										to={item.url}
										className={({ isActive }) =>
											`flex flex-row justify-center items-center px-4 py-3 text-lg text-center text-gray-700 dark:text-white hover:text-pink dark:hover:text-pink ${
												isActive
													? `text-pink`
													: `text-dark-blue dark:text-white`
											}`
										}
										onClick={() => {
											setSearchQuery("");
											setSearchResults([]);
											setViewMobileMenu(false);
										}}
										key={index}
									>
										{item?.icon && item?.icon != "" && (
											<img
												style={{
													width: "20px",
													height: "20px",
													marginRight: "8px",
													objectFit: "contain",
												}}
												src={item.icon}
											/>
										)}
										{item.label}
									</NavLink>
								)}
							</>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default DropdownMobileMenu;
